import { Native, CurrencyAmount, TradeType, Percent } from '@pancakeswap/sdk'
import { SmartRouter, SMART_ROUTER_ADDRESSES, SwapRouter } from '@pancakeswap/smart-router/evm'
import { bscTokens } from '@pancakeswap/tokens'
import { useCallback, useEffect, useMemo, useState } from 'react'

import {
  useAccount,
  useConnect,
} from 'wagmi'

import './App.css'
import { ChainId, ERC20Token } from '@pancakeswap/sdk'
import { mainnet, bsc, polygon } from 'wagmi/chains'
//import { getQuoteCurrency } from '@pancakeswap/smart-router/evm'
//import { getBestRoute } from './getQuoteMOC'
//import { getMOCpriceUSDT, trade } from './getQuoteMOC'

import { createPublicClient, hexToBigInt, http } from 'viem'
import { GraphQLClient } from 'graphql-request'

export const bscTokens1 = {
  moc: new ERC20Token(
    ChainId.BSC,
    '0xC5a05951f995cf19b7407820EFEb618Bf590D27a',
    18,
    'MOC',
    'Mosquito Coin',
    'https://mosquito.network',
  ),
  usdt: new ERC20Token(
    ChainId.BSC,
    '0x55d398326f99059fF775485246999027B3197955',
    18,
    'USDT',
    'Tether USD',
    'https://tether.to/',
  )
}

const chainId = ChainId.BSC
// const swapFrom = Native.onChain(chainId);
// const swapTo = bscTokens.usdt
const ETHorBSC = 'native'
const swapFrom = bscTokens1.usdt
const swapTo = bscTokens1.moc

const publicClient = createPublicClient({
  chain: mainnet,
  transport: http('https://bsc-dataseed1.binance.org'),
  batch: {
    multicall: {
      batchSize: 1024 * 200,
    },
  },
})

const v3SubgraphClient = new GraphQLClient('https://api.thegraph.com/subgraphs/name/pancakeswap/exchange-v3-bsc')
const v2SubgraphClient = new GraphQLClient('https://proxy-worker-api.pancakeswap.com/bsc-exchange')

/*
const [trade, setTrade] = useState<SmartRouterTrade<TradeType>  null>(null)

const amount = useMemo(() => CurrencyAmount.fromRawAmount(swapFrom, 10 ** 16), [])

const getMOCprice = useCallback(async () => {
  const [v2Pools, v3Pools] = await Promise.all([
    SmartRouter.getV2CandidatePools({
      onChainProvider: () => publicClient,
      v2SubgraphProvider: () => v2SubgraphClient,
      v3SubgraphProvider: () => v3SubgraphClient,
      currencyA: amount.currency,
      currencyB: swapTo,
    }),
    SmartRouter.getV3CandidatePools({
      onChainProvider: () => publicClient,
      subgraphProvider: () => v3SubgraphClient,
      currencyA: amount.currency,
      currencyB: swapTo,
      subgraphCacheFallback: false,
    }),
  ])
  const pools = [...v2Pools, ...v3Pools]
  const trade = await SmartRouter.getBestTrade(amount, swapTo, TradeType.EXACT_INPUT, {
    gasPriceWei: () => publicClient.getGasPrice(),
    maxHops: 2,
    maxSplits: 2,
    poolProvider: SmartRouter.createStaticPoolProvider(pools),
    quoteProvider,
    quoterOptimization: true,
  })
  setTrade(trade)
}, [amount])
*/


function SwapPancakeswap(props) {

  const { address, isConnected } = useAccount()
  const { connect, connectors } = useConnect()

  //const amount = useMemo(() => CurrencyAmount.fromRawAmount(swapFrom, 10 ** 16), [])

  

  return (
    <div className="App">
      <header className="App-header">
        <p>Pancakeswap Smart Router Example.</p>
        <p>
          Get best quote swapping from {''} {'MOC'} to{' '}
          {'' || '?'} {'BNB'}
        </p>
        <p>
          {isConnected ? (
            address
          ) : (
            <button onClick={() => connect({ connector: connectors[0] })}>Connect wallet</button>
          )}
        </p>
        <p>{<button onClick={''}>Get Quote</button>}</p>
      </header>
    </div>
  )
}

export default SwapPancakeswap