import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';


import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';


//import logo_bsc from "./assets/svg/bscscan.com.svg"
import logo_bsc_scan from "./assets/svg/bsscan.svg"
//import logo_eth from "./assets/svg/ethereum.svg"

//import logo_pancakeswap from "./assets/svg/pancakeswap.svg"
import logo_bubblemaps from "./assets/svg/bubblemaps.svg";
import logo_metamask from "./assets/svg/metamask.svg";
import logo_copy from "./assets/svg/copy.svg";

import logo_banned from "../../assets/images/dex/banned-stamp.png";
import logo_not_banned from "../../assets/images/dex/ok.png";

import logo_moc_listed from "./assets/svg/moc_listed.svg";
import logo_moc_banned from "./assets/svg/moc_banned.svg";

import logo_uniswap from "./assets/svg/uniswap.svg";
import logo_pancakeswap from "./assets/svg/pancakeswap_round.svg"

import arrowRight from "./assets/svg/arrowRight.svg";
import arrowLeft from "./assets/svg/arrowLeft.svg";
import arrowUpDown from "./assets/svg/arrowUpDown.svg";
import arrowDown from "./assets/svg/arrowDown.svg";


import ToggleButton from 'react-bootstrap/ToggleButton';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import { CoinsDataContext } from "../../ContextProviders/CoinsDataProvider";
import AddTokenToMetamask, { HandleAddToken } from './addTokenToMetamask';
import { data } from '../token/Chart';
/*
CoinList.propTypes = {
    dataCoins : PropTypes.array,
};
*/

const CoinList = () => {
    //const {dataCoins} = props;

    const { dataCoins, setDataCoins } = useContext(CoinsDataContext);

    /**TOGGLE BUTTONS (24h% Gainers) */
    const [checked, setChecked] = useState(false);
    const [radioValue, setRadioValue] = useState('2');

    const radios = [
        { name: 'Gainers', value: '1', color: 'green', variant: 'outline-success' },
        { name: 'Last listed', value: '2', color: 'grey', variant: 'outline-secondary' },
        { name: 'Loosers', value: '3', color: 'red', variant: 'outline-danger' },
    ];

    const handleSortingChange = (event) => {
        const value = event.target.value;
        setRadioValue(value);

        if (radioValue === 1 || radioValue === 3) {
            setIsAscending(false);
        }
        else if (radioValue === 2) {
            setDataCoins = dataCoins();
        }
    }

    /**TOGGLE BUTTONS (24h% Gainers) */


    // State for sorting
    const [isAscending, setIsAscending] = useState(false); // Initial sorting direction
    // States for pagination and navigation
    const [currentPage, setCurrentPage] = useState(0);
    const [tabsPerPage, setTabsPerPage] = useState(8); // Display 10 coins per page
    //const [sortOrder, setSortOrder] = useState('asc');


    const logo_eth = './images/wallets/wallet_ethereum.png';
    const logo_bsc = './images/wallets/wallet_bsc.png';
    //const logo_pancakeswap = './images/dex/pancakeswap.png';
    //const logo_uniswap = './images/dex/uniswap.png';

    const constantLogoUrl = 'https://mosquito.network/images/coins/NON_LOGO.png'; // Replace with your actual constant URL

    function coinLogo(logoURI) {
        const coinLogo = logoURI || constantLogoUrl; // Use nullish coalescing operator (??) for concise logic
    }

    function chainIMG(chainID) {
        if (chainID === 56)
            return (logo_bsc)
        if (chainID === 1)
            return (logo_eth)
    }

    function bubblemaps(chainID, address) {
        if (chainID === 56)
            return (`https://app.bubblemaps.io/bsc/token/${address}`)
        if (chainID === 1)
            return (`https://app.bubblemaps.io/eth/token/${address}`)
    }

    function chainScannerAddress(chainID, address) {
        if (chainID === 56)
            return ("https://bscscan.com/token/" + address)
        if (chainID === 1)
            return ("https://etherscan.io/token/" + address)
    }

    /*
        function dexIMG(dexImage) {
            if (dexImage === "pancakeswap-v3-bsc" || "pancakeswap_v2")
                return (logo_pancakeswap)
            if (dexImage === "uniswap-bsc")
                return (logo_uniswap)
        }
        */
    function moclisted(moc_listed) {
        if (moc_listed === 1)
            return (logo_moc_listed)
        if (moc_listed === null || moc_listed === 0)
            return (logo_moc_banned)
    }

    function mocbanned(moc_banned) {
        if (moc_banned === 1)
            return (logo_banned)
        if (moc_banned === null || moc_banned === 0)
            return (logo_not_banned)
    }

    function dexIMG(chainID) {
        if (chainID === 56)
            return (logo_pancakeswap)
        if (chainID === 1)
            return (logo_uniswap)
    }

    function dexTradeLink(chainID, address) {
        if (chainID === 56)
            return ("https://pancakeswap.finance/swap?outputCurrency=" + address)
        if (chainID === 1)
            return ("https://app.uniswap.org/swap?chain=ethereum&outputCurrency=" + address)
    }


    function addToMetamask(a, s, d, c, i) {
        a ? window.ethereum ? window.ethereum.request({
            method: "wallet_watchAsset",
            params: {
                type: "ERC20",
                options: {
                    address: a,
                    symbol: s ?? "unknown",
                    decimals: d,
                    chain: c,
                    image: i
                }
            }
        }) : console.error("Add to metamask: Metamask is not installed") : console.error("Add to metamask: cannot add to Metamask a token without address")
    }





    // Sort dataCoins based on price_change_percentage.h24
    const sortedCoins = [...dataCoins].sort(
        (a, b) =>
        (isAscending
            ? a.price_change_percentage_h24 -
            b.price_change_percentage_h24
            : b.price_change_percentage_h24 -
            a.price_change_percentage_h24
        )
    );


    // Slice the dataCoins for pagination
    const currentCoins = sortedCoins.slice(
        currentPage * tabsPerPage,
        (currentPage + 1) * tabsPerPage
    );

    // Functions for navigation

    const handleNextPage = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, Math.ceil(dataCoins.length / tabsPerPage) - 1));
    };

    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 0));
    };







    return (

        <section className="tf-section project-dex">
            <div className="container">
                <div className="row" >
                    <div className="col-md-12">


                        <Tabs>


                            {/*
                            <div className="project-coinslist-topp-bar check-radio">
                                <TabList>

                                    <div className="form-inner">

                                                <ul className="list">
                                                    <li className="list__item">
                                                        <input type="radio" className="radio-btn" name="choice" id="a-opt" />
                                                        <label htmlFor="a-opt" className="label">All</label>
                                                    </li>
                                                    <li className="list__item">
                                                        <input type="radio" className="radio-btn" name="choice" id="b-opt" />
                                                        <label htmlFor="b-opt" className="label">Binance Smart Chain</label>
                                                    </li>
                                                    <li className="list__item">
                                                        <input type="radio" className="radio-btn" name="choice" id="c-opt" />
                                                        <label htmlFor="c-opt" className="label">Solana</label>
                                                    </li>

                                                    <li className="list__item">
                                                        <input type="radio" className="radio-btn" name="choice" id="d-opt" />
                                                        <label htmlFor="d-opt" className="label">Ethereum</label>
                                                    </li>
                                                    <li className="list__item">
                                                        <input type="radio" className="radio-btn" name="choice" id="e-opt" />
                                                        <label htmlFor="e-opt" className="label">Polygon (Matic)</label>
                                                    </li>
                                                </ul>



                                        <ul>
                                            <li>
                                                <ButtonGroup className="gap-2">
                                                    {radios.map((radio, idx) => (
                                                        <ToggleButton
                                                            key={idx}
                                                            id={`radio-${idx}`}
                                                            type="radio"
                                                            variant={radio.variant}
                                                            name="radio"
                                                            value={radio.value}
                                                            checked={radioValue === radio.value}
                                                            onChange={(e) => handleSortingChange(e.currentTarget.value)}
                                                        >
                                                            {radio.name}
                                                        </ToggleButton>
                                                    ))}
                                                </ButtonGroup>
                                            </li>
                                        </ul>


                                    </div>
                                </TabList>
                            </div>
                        */}


                            {
                                sortedCoins.map(idx => (
                                    <TabPanel key={idx.id} className='content-tab mt40'>
                                        <div className="content-inner coinslist-box-style3_wrapper">
                                            {
                                                currentCoins.map(item => (
                                                    <div key={item?.id} className="coinslist-box-style3" >
                                                        <div className="header_project">
                                                            <div className="image">
                                                                <img className="mask" src={item.logoURI} alt="" />
                                                                <div className="shape">
                                                                    
                                                                    {item.moc_banned === 1 ? (
                                                                        <img src={mocbanned(item.moc_banned)} alt={`${item.moc_banned} Logo`} width="24" height="24" />
                                                                    ) : null}
                                                                    {item.moc_listed === 1 ? (
                                                                        <img src={moclisted(item.moc_listed)} alt={`${item.moc_listed} Logo`} width="24" height="24" />
                                                                    ) : null}

                                                                </div>
                                                            </div>
                                                            <h5 className="heading"><Link to={`/coin-details/${item.id}`}>{item.name}</Link></h5>
                                                        </div>

                                                        <div className="content">
                                                            <div className="td td1">
                                                                <p>Symbol</p>
                                                                <p >{item.symbol}</p>
                                                            </div>
                                                            <div className="td td1">
                                                                <p>Pool</p>
                                                                <p >{item?.name}</p>
                                                            </div>
                                                            <div className="td td2">
                                                                <p>Price</p>
                                                                <p style={{
                                                                    color: item?.price_change_percentage_h24 > 0
                                                                        ? "green"
                                                                        : item?.price_change_percentage_h24 < 0
                                                                            ? "red"
                                                                            : "grey",
                                                                }}
                                                                >{new Intl.NumberFormat('en-US', {
                                                                    style: 'currency',
                                                                    currency: 'USD',
                                                                    minimumFractionDigits: 2, // Ensure at least 8 decimal places
                                                                    maximumFractionDigits: 8  // Limit to 8 decimal places
                                                                }).format(item?.price_usd)}
                                                                </p>
                                                            </div>
                                                            <div className="td td3">
                                                                <p>Chain</p>
                                                                <p>
                                                                    <img src={chainIMG(item.chainId)} alt={`${item.chainId} Logo`} width="24" height="24" />
                                                                </p>
                                                            </div>
                                                            <div className="td td4">
                                                                <p>% 24h</p>
                                                                <p
                                                                    style={{
                                                                        color: item?.price_change_percentage_h24 > 0
                                                                            ? "green"
                                                                            : item?.price_change_percentage_h24 < 0
                                                                                ? "red"
                                                                                : "grey",
                                                                    }}
                                                                >{item?.price_change_percentage_h24}%
                                                                </p>
                                                            </div>
                                                            <div className="td td5">
                                                                <ul className="social">
                                                                    <li>
                                                                        <Link onClick={() => navigator.clipboard.writeText(item.address)}>
                                                                            <img src={logo_copy} alt={`copy Logo`} width="24" height="24" />
                                                                        </Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link onClick={() => addToMetamask(item.address, item.symbol, item.decimals, item.chainId, item.logoURI)}>
                                                                            <img src={logo_metamask} alt={`metamask Logo`} width="24" height="24" />
                                                                        </Link>
                                                                    </li>
                                                                    <li>
                                                                        {<Link to={dexTradeLink(item.chainId, item.address)}>
                                                                            <img src={dexIMG(item.chainId)} alt={`${item.chainId} Logo`} width="24" height="24" />
                                                                        </Link>}
                                                                    </li>
                                                                    <li>
                                                                        <Link to={chainScannerAddress(item.chainId, item.address)}>
                                                                            <img src={logo_bsc_scan} alt={`${item.chainId} Logo`} width="24" height="24" />
                                                                        </Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link to={bubblemaps(item.chainId, item.address)
                                                                        }>
                                                                            <img src={logo_bubblemaps} alt={`${item.chainId} Logo`} width="24" height="24" />
                                                                        </Link>
                                                                    </li>
                                                                    {item.moc_banned === 1 ? (
                                                                        <li>
                                                                            {<Link >
                                                                                <img src={mocbanned(item.moc_banned)} alt={`${item.moc_banned} Logo`} width="24" height="24" />
                                                                            </Link>}
                                                                        </li>
                                                                    ) : null}
                                                                    {item.moc_listed === 1 ? (
                                                                        <li>
                                                                            {<Link >
                                                                                <img src={moclisted(item.moc_listed)} alt={`${item.moc_listed} Logo`} width="24" height="24" />
                                                                            </Link>}
                                                                        </li>
                                                                    ) : null}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }

                                        </div>

                                    </TabPanel>
                                ))
                            }



                        </Tabs>




                    </div>
                    <div className="col-md-12">
                        <ul className="panigation mt6" >
                            <li>
                                <Link to="#" onClick={() => handlePreviousPage()}>
                                    <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.5 1L1.5 6L6.5 11" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </Link>
                            </li>
                            <li>
                                <Link to="#" className="active">
                                    {currentPage + 1}
                                </Link>
                            </li>
                            <li>
                                <Link to="#" onClick={() => handleNextPage(currentPage + 1)}>
                                    {currentPage + 2}
                                </Link>
                            </li>
                            <li>
                                <Link to="#" onClick={() => handleNextPage(currentPage + 2)}>
                                    {currentPage + 3}
                                </Link>
                            </li>
                            <li>
                                <Link to="#" onClick={() => handleNextPage(currentPage + 1)}>
                                    <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.5 1L6.5 6L1.5 11" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>

    );
}

export default CoinList;