// This file stores web3 related constants such as addresses, token definitions, ETH currency references and ABI's

import { Token } from '@uniswap/sdk-core'
//import { mainnet } from 'wagmi'

// Addresses

export const POOL_FACTORY_CONTRACT_ADDRESS =
  '0x0BFbCF9fa4f9C56B0F40a671Ad40E0805A091865'
export const QUOTER_CONTRACT_ADDRESS =
  '0xB048Bbc1Ee6b733FFfCFb9e9CeF7375518e25997'

// Currencies and Tokens

export const MOC_TOKEN = new Token(
  56,
  '0xC5a05951f995cf19b7407820EFEb618Bf590D27a',
  18,
  'MOC',
  'Mosquito Coin'
)

export const USDT_TOKEN = new Token(
  56,
  '0x55d398326f99059fF775485246999027B3197955',
  18,
  'USDT',
  'USD//T'
)

export const WBNB_TOKEN = new Token(
  56,
  '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  18,
  'WBNB',
  'Wrapped BNB'
)