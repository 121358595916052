import React from 'react';
import '../scss/component/_section.scss'; 
import '../scss/component/_box.scss'; 
import '../scss/component/_tf-section.scss'; 
import '../scss/component/_reponsive.scss'; 
import Banner from '../components/banner/banner_v2'; 
import dataBanner from '../assets/data/data-banner';
import Project from '../components/project/project_v1';  
import Project2 from '../components/project/project_v2';  
import dataProject from '../assets/data/data-project';
import Project3 from '../components/project/project_v3';  
import Project4 from '../components/project/project_v4';  
import Token from '../components/token';

import Team from '../components/team';
import Partner from '../components/partner';
import dataPartner from '../assets/data/data-partner';
import CTA from '../components/cta';
import dataTeam from '../assets/data/data-team';
import TokensInWallet2 from '../components/WalletTokens/TokensInWallet2.jsx'
import WalletTokenList from '../components/mocTokenList_whitelist/WhitelistedTokens.jsx';
import BannedTokenList from '../components/mocTokenList_Banned/BannedTokens.jsx';
import WhitelistedTokens_with_Price from '../components/mocTokenList_whitelist_with_price/WhitelistedTokens.jsx'


import getMOCpriceUSDT from '../Hook/useGetMOCprice.jsx';
import TOKENCARDS from '../components/TOKENCARDS/TOKENCARDS.jsx';

import useTokenTransactions from '../Hook/BSCSCAN_API/useTokenTransactions.jsx'
import CoinGecoAPI_TokenList from '../components/CoinGecoAPI_TokenList/CoinGecoAPI_TokenList.jsx'

function HomeVIP(props) {
    return (
        <div className='header-fixed main home1 counter-scroll'>

           {<Banner data={dataBanner} />}
           {/*<Project2 data={dataProject}/>*/}
           {/*<Project3 data={dataProject}/>*/}

            {/*<Token />*/}

            {/*useTokenTransactions()*/}

            {/*<TOKENCARDS/>*/}



            {/*<Project4 data={dataProject} />*/}

            
            {/*<CoinGecoAPI_TokenList/>*/}

            {<WhitelistedTokens_with_Price/>}
            {<BannedTokenList/>}




        </div>
    );
}

export default HomeVIP;