import { useBalance, useAccount } from 'wagmi'
import { useEffect, useState } from 'react'


function GetMOCTokenBalanceOnChain({ onBalanceFetchMOC }) {
  const ContractMOC = '0xC5a05951f995cf19b7407820EFEb618Bf590D27a';   // Web3 MOC
  const { address, isConnected, isDisconnected } = useAccount()


  const { data, isSuccess } = useBalance({
    address: address,
    token: ContractMOC,
    chainId: 56,
    watch: true,
    cacheTime: 5_000,
  })

  useEffect(() => {
    if (isSuccess && data) {

      let balance = data?.formatted;
      // Convert the balance to a number and format it with two decimal places
      balance = parseFloat(balance).toFixed(2);

      onBalanceFetchMOC(balance)
    }
  }, [isSuccess, data])

  return null
}

export default GetMOCTokenBalanceOnChain;
