import React from 'react';


class DexToolVisitMOCPair extends React.Component {
  render() {

const DexTool_MOC_USDT_PAIR = "https://www.dextools.io/app/en/bnb/pair-explorer/0x6b6e9d8c1d5523f8aaf33f0b2f0186686fdb261c"

    return (
      <iframe 
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: -1
        }}
        src={DexTool_MOC_USDT_PAIR}
      />
    );
  }
}

export default DexToolVisitMOCPair;

/*
In this example, the iframe is positioned absolutely to cover the entire page, 
and its zIndex is set to -1 to ensure it stays in the background. 
Replace "https://www.example.com" with the URL of the website you want to load.

Please note that not all websites allow their pages to be loaded in an iframe 
due to the X-Frame-Options or Content-Security-Policy HTTP headers. 
You’ll need to make sure the website you’re trying to load does not have these restrictions. 
Also, keep in mind the ethical and legal considerations when loading another website in your application. 
Always respect the terms of service of the website you’re loading.

*/