import React, { useState, useEffect } from 'react';

// ... other imports
import SearchBox from '../components/DexTools_List/SearchBox.jsx';
import CoinsList from '../components/DexTools_List/CoinsList.jsx'
import TokenRollerBar from '../components/DexTools_List/TokenRollerBar.jsx'
import TokenDexListing from '../components/DexTools_List/TokenDexListing.jsx'

// ... Context Providers
import { CoinsDataProvider } from "../ContextProviders/CoinsDataProvider.jsx";

import UpdateUserWallet from '../Hook/Web3/GetUserWalletBalance.jsx'
import RegisterToken from '../Hook/MOC_API/RegisteredTokenList.jsx'


import WhitelistedTokens_with_Price from '../components/mocTokenList_whitelist_with_price/WhitelistedTokens.jsx'

function Dex(props) {



  //const GetCoinsData = "./CoinsData.json"; // public folder


  return (
    <div className='header-fixed main home3dex counter-scroll'>
      {/*
    <FeaturedSwiperContent/>
  */}
      <CoinsDataProvider>


        <SearchBox />

        <TokenRollerBar />


        <CoinsList />



      </CoinsDataProvider>



      {/*

        <RegisterTokenToApi />

         <RegisterToken />

      <UpdateUserWallet />

      <RegisterToken />

      <WhitelistedTokens_with_Price/>

      <CoinsDataProvider>
        <CoinsList />
      </CoinsDataProvider>
*/}
    </div>


  );
}

export default Dex;