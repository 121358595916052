import React, { useEffect, useState } from 'react';

const GetMOCTokenPrice_GECKO = () => {
  const [price, setPrice] = useState(null);


  useEffect(() => {
    const token_id = 'bsc';  // replace with your token id
    const contract_address = '0xC5a05951f995cf19b7407820EFEb618Bf590D27a';  // replace with your contract address
    const url = `https://api.geckoterminal.com/api/v2/networks/${token_id}/tokens/${contract_address}?include=top_pools`; //Get Specific token on network(token_id)
    //const url = `https://api.coingecko.com/api/v3/coins/${token_id}/contract/${contract_address}`;

    fetch(url)
      .then(response => response.json())
      .then(data => setPrice(data.data.attributes.price_usd))
      .catch(error => console.error(error));
  }, []);

  return (
      price
  );
};

export default GetMOCTokenPrice_GECKO;
