import React from 'react';

import {
  useAccount,
  useConnect,
  useDisconnect,
  useEnsAvatar,
  useEnsName,
} from 'wagmi'
 
import '../Hook/styles.scss';
import Button from '../components/button/index'; 

function Profile() {
  const { address, isConnected, isDisconnected } = useAccount()


  const { disconnect } = useDisconnect()

 
  if (isConnected) {
    return (
      <div>
        {
          /*
          <div>{ensName ? `(${address})` : `${address.substring(0,6)}......${address.substring(36, 40)}`}</div>
          */
          }

        <button onClick={disconnect} className={`tf-button style2`}>Disconnect</button>
      </div>
    )
  }


  if (isDisconnected) {
  return (
    <div>
                        {isDisconnected &&
                            <Button title={'Connect'} path='/connect' addclass='style1'>
                                Connect
                            </Button>}
    </div>
  )
}

}

export default Profile;