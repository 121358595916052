import React, { useEffect, useState, useContext } from "react"

import { Link } from 'react-router-dom';
import { color } from 'highcharts';
import {
    useAccount,
    useBalance,
    useSwitchChain,
    isConnected,
    isDisconnected,
    useReadContract
} from 'wagmi';
import { formatUnits } from 'ethers/lib/utils';
import { getBalance } from '@wagmi/core'

import { chain, mainnet, bsc, polygon, arbitrum, optimism } from 'wagmi/chains';

import SVG_BscScan from '../../assets/svg/BscScan.svg'
import SVG_CMC from '../../assets/svg/coinmarketcap-svgrepo-com.svg'
import SVG_Pancakeswap from '../../assets/svg/pancakeswap.svg'


import tokenList_getImageUrl from '../mocTokenList_whitelist/tokenList_getImageUrl'
import GetTokenPrice from '../../Hook/CoinGecko_API/GetTokenPrice_GECKO'


import { CoinsDataContext } from "../../ContextProviders/CoinsDataProvider";
import { erc20Abi, erc721Abi } from "viem";
import { result } from "lodash";




function TokensInWallet_MOC_API(props) {
    const { dataCoins, setDataCoins } = useContext(CoinsDataContext);
    const { address: WalletAddress } = useAccount();


    const [TokensInWallet, setTokensInWallet] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);


    const [coinaddress, setCoinAddress] = useState(null)
    const [coinChainId, setCoinChainId] = useState(null);

    const [balancesNative, setBalancesNative] = useState([]);
    const { address, chain } = useAccount();




    const abiBalance = [
        {
            type: 'function',
            name: 'balanceOf',
            stateMutability: 'view',
            inputs: [{ name: 'account', type: 'address' }],
            outputs: [{ type: 'uint256' }],
        },
        {
            type: 'function',
            name: 'totalSupply',
            stateMutability: 'view',
            inputs: [],
            outputs: [{ name: 'supply', type: 'uint256' }],
        },
    ]


    



        const { data: tokenBalanceData, error: isErrortokenBalanceData, isLoading: isLoadingtokenBalanceData, isSuccess } = useReadContract({
            abi: erc20Abi,
            address: coinaddress,
            args: [WalletAddress],
            functionName: 'balanceOf',
            chainId: coinChainId,
        })



    //const coins = dataCoins();




    useEffect(() => {

        //console.log("DataCoins: " + dataCoins)

        const updateTokens = async () => {
            const newTokensInWallet = []; // Temporary array


            for (const coin of dataCoins) {
                try {

                    setCoinAddress(coin.address);
                    setCoinChainId(coin.chainId);

                    //if (isErrortokenBalanceData) { console.log(isErrortokenBalanceData) };
                   // if (isLoadingtokenBalanceData) { console.log(isLoadingtokenBalanceData) };



                        const balance = (await tokenBalanceData ? formatUnits(tokenBalanceData, coin.decimals) || '0' : '0'); // Default value if tokenBalanceData is undefined 
                        const balanceFixed = parseFloat(balance).toFixed(2);
                        
                        console.log("tokenBalance: "+balanceFixed);
                        console.log("token: "+coin.symbol);
    
                        const valueUSD = Number.isFinite(parseFloat(balance)) ? parseFloat(balance) * coin.price_usd : 0;
                        valueUSD.toFixed(2);

                        console.log("valueUSD: "+valueUSD);
    
                        if(balanceFixed){
                        newTokensInWallet.push({
                            logoURI: coin.logoURI,
                            symbol: coin.symbol,
                            chainId: coin.chainId,
                            address: coin.address,
                            valueUSD: valueUSD,
                            price_usd: coin.price_usd,
                            balance: balance
                        });
                    }
                    else{
                        //console.log("error: " + isErrortokenBalanceData);
                    }

                    /*
                    //if (0.01 >= valueUSD) {
                    // }
                    */
                } catch (error) {
                    console.log("Wallet Failed: " + error)
                    //console.error(error);
                }
            }
            setTokensInWallet(newTokensInWallet); // Update once, outside the loop 
            console.log("TokensInWallet:", TokensInWallet);
        };
        
    updateTokens();
    }, [dataCoins, tokenBalanceData]); // The dependency array ensures the effect runs when 'coins' changes








    return (
        <div>


            <section className="tf-section tf-token pt20">
                <div className="container">
                    <div className="row">

                        {/*
                        console.log(TokensInWallet)
                        {console.log(isLoadingtokenBalanceData)}

                        {console.log(isErrortokenBalanceData)}

                         */
                        }



                        <div className="col-md-12">
                            <div className="tokenomics-wrapper" data-aos="fade-up" data-aos-duration="100">

                                {TokensInWallet?.map((coin, index) => (
                                    <li key={index}>
                                        <p></p>
                                        <div className="box">

                                            <div className="td td1">
                                                <img
                                                    src={tokenList_getImageUrl(coin)}
                                                    className="tokenImage"
                                                    alt={coin?.logoURI}
                                                />
                                            </div>
                                            <div className="td td1">
                                                <p>Symbol</p>
                                                <p><Link to={("https://bscscan.com/token/" + coin?.address)}>{coin?.symbol}</Link></p>
                                            </div>

                                            <div className="td td2">
                                                <p>Price</p>
                                                <p>${coin?.price_usd}</p>
                                            </div>
                                            <div className="td td7">
                                                <p>Amount</p>
                                                <p>{coin?.balance}</p>
                                            </div>
                                            <div className="td td10">
                                                <p>ValueUSD</p>
                                                <p>{coin?.valueUSD}</p>
                                            </div>
                                        </div>

                                    </li>

                                ))}



                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </div>
    );
}

export default TokensInWallet_MOC_API;