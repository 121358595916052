//import React from 'react';
import React, { useState, useCallback } from 'react'
import CTA from '../components/cta/cta_v2/index.jsx';
import AboutUs from '../components/AboutUs/index.jsx'
import Airdrop from '../components/airdrop/index';
import NotVIPmessage from '../components/notVIPmessage/index.jsx';
import SOCIAL from '../components/social/index.jsx';
import Banner from '../components/banner/banner_MOC/index.jsx'; 
import dataBanner from '../assets/data/data-banner.js';
import Team from '../components/team/index.jsx';
import dataTeam from '../assets/data/data-team.js';
import WalletTokenList from '../components/mocTokenList_whitelist/WhitelistedTokens.jsx';
import SwapUniswap from '../components/swap/uniswap/index.jsx';
import { quote } from '../Hook/UniswapTools/libs/quote.ts';
import { CurrentConfig } from '../Hook/UniswapTools/config.ts';
import SwapPancakeswap from '../components/swap/pancakeswap/swap.jsx';
import { useBalance } from 'wagmi';


function About(props) {

    const [outputAmount, setOutputAmount] = useState('')

    const onQuote = useCallback(async () => {
      setOutputAmount(await quote())
    }, [])



    return (
        <div className='header-fixed main home1 counter-scroll'>
           {<Banner data={dataBanner} />}

            <AboutUs/>
            <Team data={dataTeam} />
            <SOCIAL/>



        </div>
    );
}

export default About;