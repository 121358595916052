// CoinsDataProvider.js
import React, { createContext, useState, useEffect } from 'react';
import { GET_CoinsData, GET_tokens } from '../Hook/MOC_API/Get_CoinsData';

// Create a Context
export const CoinsDataContext = createContext();

// Create a Provider Component
export const CoinsDataProvider = props => {
  const [dataCoins, setDataCoins] = useState([]); // Start with null
  const [isLoadingDataCoins, setIsLoading] = useState(true);
  const [errorDataCoins, setError] = useState(null);



  // GET tokens from api
  const MOC_API_get_CoinsList = GET_tokens();

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const response = await GET_tokens(); //API

        //console.log(response);  // Log the entire response object 
        console.log(response.status); // Look at the status code

        //const data = await MOC_API_get_CoinsList;
        setDataCoins(response);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching ccdata:", error);
        // Handle the error here, e.g., display an error message to the user
      }
    }
    fetchData();
  }, []);

  return (
    <CoinsDataContext.Provider value={{ dataCoins, setDataCoins, isLoadingDataCoins, errorDataCoins }}>
      {props.children}
    </CoinsDataContext.Provider>
  );
};
