import React, { useRef, useState } from 'react';

import { Link, useHref } from 'react-router-dom';


import BalanceMOC from '../../Hook/BalanceMOC';
import Button from '../button/index'; 


import svg_pancakeswap from '../../assets/svg/pancakeswap.svg';


import Popup from '../popup/index';
import { useContractWrite } from 'wagmi'
 
import MOCico from '../ABI/mocICO.json'

import { parseEther } from 'viem';
import { parseGwei } from 'viem';

import { 
    useAccount,
    useBalance,
    useConnect,
    useDisconnect, 
    isConnected,
    isDisconnected,
    useEnsName,
    address,
} from 'wagmi';




function NotVIPmessage(props) {

    
    const { address, isConnected, isDisconnected } = useAccount()
    const AddressAirdropClaimer = address;
    const AddressAirdropContract = '0xf7E1124B3799260c5Eed307bC3E0cd110dAf46Fd';

    const bnbValueAirdrop = parseEther((0.00000004762 * 2000000).toString());


    const { data, error: contractWriteError, isLoading, isSuccess, write } = useContractWrite({
        address: AddressAirdropContract,
        abi: MOCico.abi,
        functionName: 'buyToken',
      })

    // Web3 MOC
    //const { address, connector, isConnected } = useAccount()
    const { data: ensName } = useEnsName({ address })

    const { disconnect } = useDisconnect()



    var addressSplitted = '';

    if(isConnected){
     if (!ensName)
     {
         addressSplitted = 'Connect';
     }
     addressSplitted = `${address.substring(0,6)}......${address.substring(36, 40)}`;
    }







    return (
        <section className="tf-section tf-contact pt60">
        <div className="container">
            <div className="row"> 
                <div className="col-md-12">
                    <div className="tf-title mb40" data-aos="fade-up" data-aos-duration="800">

                    {isDisconnected &&
                    <h2 >Connect wallet to get started!</h2>}

                    {isConnected &&
                    <h2 >hold 2M MOC to enter</h2>}

                    {<Button title={'Hold 2.000.000 MOC to enter'}/>}

                    <li>{isConnected &&
                <Button
                title={'Fast Trade 2M MOC'}
                    disabled={!write}
                    onClick={() =>
                        write({
                            //args: [69],
                            from: AddressAirdropClaimer,
                            value: bnbValueAirdrop,
                            chainId: 56,
                            overrides: { from: AddressAirdropClaimer, value: bnbValueAirdrop, },
                            gasPrice: parseGwei('3'),
                            gas: 200_000n,
                        })
                    } addclass='style2'>
                        </Button>}
                        {isLoading && <div>Check Wallet</div>}
                        {isSuccess && <div>Transaction: {JSON.stringify(data)}</div>}
                        </li>



                        {isDisconnected &&<Popup/>}
                        <h5>
                        {isConnected && "You have" }{/*<BalanceMOC/>*/}
                        </h5>


                    </div>
                </div> 


            </div>
        </div>
    </section> 
    );
}

export default NotVIPmessage;