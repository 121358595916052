import img1 from '../images/post/post_1.jpg';
import img2 from '../images/post/post_2.jpg';



const dataBlog = [
    {
        id: 1,
        img: img1,
        heading: 'Decoded: Metaverse and the future of banking',
        cate: 'Metaverse',
        text: 'The metaverse can help the healthcare fraternity by providing trainings on understanding medical tools and...'
    },
    {
        id: 2,
        img: img2,
        heading: 'Tapping the Metaverse for immersive service',
        cate: 'Web 3.0',
        text: 'The metaverse can help the healthcare fraternity by providing trainings on understanding medical tools and...'
    }, 


]

export default dataBlog;