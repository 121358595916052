import React, { useState, useEffect, useRef, useContext } from 'react';
import { CoinsDataContext } from "../../ContextProviders/CoinsDataProvider";
import { Link, useNavigate } from 'react-router-dom'; // Import from React Router DOM
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { GET_pulseCheck } from '../../Hook/MOC_API/Get_CoinsData'

import greenIcon from './assets/svg/greenIcon.svg';
import redIcon from './assets/svg/redIcon.svg';

function SearchBox(props) {

  const { dataCoins, setDataCoins } = useContext(CoinsDataContext);
  const [pulseCheck, setpulseCheck] = useState(null);

  const [isLoading, setIsLoading] = useState(false); // Add a state for loading


  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);


  const navigate = useNavigate(); // Obtain useNavigate hook
  const dropdownRef = useRef(null); // Create a reference

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);

    // Filter options based on input
    const newFilteredData = dataCoins.filter((coin) =>
      coin.name.toLowerCase().includes(event.target.value.toLowerCase()) ||
      coin.symbol.toLowerCase().includes(event.target.value.toLowerCase()) ||
      coin.address.toLowerCase().includes(event.target.value.toLowerCase())
    );

    setFilteredData(newFilteredData);
    setIsSearchOpen(true); // Show the dropdown
  };


  const handleClick = (coin) => {
    setSelectedOption(coin);
    setSearchTerm(coin.name);
    //setIsSearchOpen(false); // Close the dropdown

    // Navigate to the details page when the item is clicked
    navigate(`/coin-details/${coin.id}`); // Assuming coin.id is the unique identifier
  };

  const handleClickOutside = (event) => {
    // Click outside only if dropdown is open and the click isn't within the dropdown itself
    if (isSearchOpen && dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsSearchOpen(false);
      setSearchTerm(""); // Clear the search term as needed
    }
  };

  useEffect(() => {
    // Add event listener when the dropdown is open
    if (isSearchOpen) {
      document.addEventListener('click', handleClickOutside);
    }

    // Cleanup function: Remove event listener
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isSearchOpen]);

  const checkPulse = async () => {
    try {
      const response = await GET_pulseCheck();

      if (response === true) {
        setpulseCheck(true);
      } else if (response.status === 404) {
        setpulseCheck(false);
      } else {
        setpulseCheck(false);
      }
    } catch (error) {
      setpulseCheck(false);
      console.error('Error during pulse check:', error);
    }
  };


  useEffect(() => {

    checkPulse();

  }, [pulseCheck]);


  return (
    <section className="tf-dex pt60">
      <div className="container">
        <div className="row">
          <div className="col-md-12">



            <div className="dropdown-container" ref={dropdownRef}>
              {
                /*
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19.0008 18.9998L13.8038 13.8028M13.8038 13.8028C15.2104 12.3962 16.0006 10.4885 16.0006 8.49931C16.0006 6.51011 15.2104 4.60238 13.8038 3.19581C12.3972 1.78923 10.4895 0.999023 8.50028 0.999023C6.51108 0.999023 4.60336 1.78923 3.19678 3.19581C1.79021 4.60238 1 6.51011 1 8.49931C1 10.4885 1.79021 12.3962 3.19678 13.8028C4.60336 15.2094 6.51108 15.9996 8.50028 15.9996C10.4895 15.9996 12.3972 15.2094 13.8038 13.8028V13.8028Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>*/
              }
              <div className="search-box"> {/* Add a container for styling */}
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19.0008 18.9998L13.8038 13.8028M13.8038 13.8028C15.2104 12.3962 16.0006 10.4885 16.0006 8.49931C16.0006 6.51011 15.2104 4.60238 13.8038 3.19581C12.3972 1.78923 10.4895 0.999023 8.50028 0.999023C6.51108 0.999023 4.60336 1.78923 3.19678 3.19581C1.79021 4.60238 1 6.51011 1 8.49931C1 10.4885 1.79021 12.3962 3.19678 13.8028C4.60336 15.2094 6.51108 15.9996 8.50028 15.9996C10.4895 15.9996 12.3972 15.2094 13.8038 13.8028V13.8028Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                &nbsp;&nbsp;
                <input
                  type="text"
                  placeholder="Search Coin, Name, Symbol, Contract address"
                  value={searchTerm}
                  onChange={handleSearch}
                  onClick={() => setIsSearchOpen(true)}
                />
              </div>

              <div>
                {pulseCheck ? (
                  <img src={greenIcon} alt="Green Icon" />
                ) : (
                  <img src={redIcon} alt="Red Icon" />
                )}
              </div>


              {isSearchOpen && (
                <ul className="dropdown-list">

                  {isLoading ? (
                    <div className="loading-message">Loading results...</div>
                  ) : (

                    filteredData.length > 0 ? (

                      filteredData.map((coin) => (
                        <li
                          key={coin.id}
                          className="dropdown-item"
                          onClick={() => handleClick(coin)}
                        >
                          <img src={coin.logoURI} alt="" width={24} />
                          <div style={{ color: 'white' }} >{coin.name} ({coin.symbol})</div>
                          <p>Address: {coin.address}</p>
                          {/* Add more relevant coin info here */}
                        </li>
                      ))
                    ) : (
                      <li className="dropdown-item">No coins found matching your search.</li>
                    ))}
                </ul>
              )}





            </div>







          </div>
        </div>
      </div>
    </section>
  );

}

export default SearchBox;