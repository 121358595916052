const menus = [
    {
        id: 1,
        name: 'MOC',
        links: '/',
    },

    
]

export default menus;