import React, { useState, useEffect } from 'react';

import { Link, NavLink } from 'react-router-dom';
import menus from '../../pages/menu';
import Button from '../button connect_disconnect/index.jsx';
import logo_red from '../../assets/images/logo/logo.png';
import logo_blue from '../../assets/images/logo/moc_logo_blue_round.png'
import Popup from '../popup/index';

import {
    useAccount,
    useConnect,
    useDisconnect,
    useEnsName,
} from 'wagmi';


import Profile from '../../Hook/Profile';
import BalanceMOC from '../../Hook/BalanceMOC';
import GetMOCTokenPrice_GECKO from '../../Hook/CoinGecko_API/GetMOCTokenPrice_GECKO.jsx';


const Header = () => {


    // Web3 MOC
    const { address, connector, isConnected } = useAccount()
    const { data: ensName } = useEnsName({ address })

    const { disconnect } = useDisconnect()




    const [scroll, setScroll] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 300);
        });
        return () => {
            setScroll({});
        }
    }, []);

    const [menuActive, setMenuActive] = useState(null);

    const handleMenuActive = () => {
        setMenuActive(!menuActive);
    };


    const [activeIndex, setActiveIndex] = useState(null);
    const handleDropdown = index => {
        setActiveIndex(index);
    };

    var addressSplitted = '';

    if (isConnected) {
        if (!ensName) {
            addressSplitted = 'Connect';
        }
        addressSplitted = `${address.substring(0, 6)}......${address.substring(36, 40)}`;
    }






    return (
        <header id="header_main" className={`header ${scroll ? 'is-fixed' : ''}`}>
            <div className="container">
                <div id="site-header-inner">
                    <div className="header__logo">
                        <NavLink to="/"><img src={logo_red} alt="MOC_IMG" width={50} height={50} /></NavLink>
                    </div>


                    <nav id="main-nav" className={`main-nav ${menuActive ? 'active' : ''}`} >
                        <ul id="menu-primary-menu" className="menu">
                            <div className={`mobile-button ${menuActive ? 'active' : ''}`} onClick={handleMenuActive}><span></span></div>
                            {
                                menus.map((data, idx) => (
                                    <li key={idx} onClick={() => handleDropdown(idx)} className={`menu-item ${data.namesub ? 'menu-item-has-children' : ''} ${activeIndex === idx ? 'active' : ''}`}

                                    >
                                        <Link to={data.links}>{data.name}</Link>
                                        {
                                            data.namesub &&
                                            <ul className="sub-menu">
                                                {
                                                    data.namesub.map((submenu) => (
                                                        <li key={submenu.id} className="menu-item"><NavLink to={submenu.links} onClick={handleDropdown}>{submenu.sub}</NavLink></li>
                                                    ))
                                                }
                                            </ul>
                                        }

                                    </li>
                                ))
                            }
                            <li>
                                <NavLink to="https://pancakeswap.finance/swap?outputCurrency=0xC5a05951f995cf19b7407820EFEb618Bf590D27a&chain=bsc"><img src={logo_blue} alt="MOC_IMG" width={25} height={25} />
                                    ${<GetMOCTokenPrice_GECKO />}
                                </NavLink>
                            </li>

                            <Popup />
                        </ul>
                    </nav>


                    <div className={`mobile-button ${menuActive ? 'active' : ''}`} onClick={handleMenuActive}><span></span></div>




                </div>
            </div>
        </header>


    );
}

export default Header;