import { useBalance, useAccount } from 'wagmi'
import { useEffect, useState } from 'react'
import { chain, mainnet, bsc, polygon, arbitrum, optimism } from 'wagmi/chains';

function GetBalancePoS({ onBalanceFetchPoS }) {
  const { address, isConnected } = useAccount();



  const { data, isSuccess } = useBalance({
    address: address,
    chainId: polygon.id,
    watch: true,
    cacheTime: 5_000,
  })

  useEffect(() => {
    if (isSuccess && data) {

      let balance = data?.formatted;
      // Convert the balance to a number and format it with two decimal places
      balance = parseFloat(balance).toFixed(4);

      onBalanceFetchPoS(balance)
    }
  }, [isSuccess, data])

  return null
}

export default GetBalancePoS;
