//import React from 'react';
import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';


//import WalletConnector from '../Hook/WalletConnector';



import {
    useAccount,
    useConnect,
    useDisconnect,
    useEnsAvatar,
    useEnsName,
    useBalance,
    useSwitchChain
} from 'wagmi'


//import BalanceMOC from '../Hook/BalanceMOC';
//import BalanceBNB from '../Hook/BalanceBNB';
//import TokensInWallet2 from '../components/WalletTokens/TokensInWallet2';
import TokensInWallet_MOC_API from '../components/WalletTokens/TokensInWallet_MOC_API';

import GetMOCTokenBalanceOnChain from '../Hook/Web3/GetMOCTokenBalanceOnChain';
import GetBalanceBSC from '../Hook/Web3/GetBalanceBSC.jsx';
import GetBalanceETH from '../Hook/Web3/GetBalanceETH';
import GetBalancePoS from '../Hook/Web3/GetBalancePoS.jsx';
import GetBalanceOP from '../Hook/Web3/GetBalanceOP.jsx';

// ... Context Providers
import { CoinsDataProvider } from "../ContextProviders/CoinsDataProvider.jsx";
import { UserWalletProvider } from "../ContextProviders/UserWalletProvider.jsx";
import { chain, mainnet, bsc, polygon, arbitrum, optimism } from 'wagmi/chains';


function ConnectWallet(props) {


    const { address, connector, isConnected, isDisconnected } = useAccount()
    const { disconnect } = useDisconnect()
    const { data: ensName } = useEnsName({ address })
    const { data: ensAvatar } = useEnsAvatar({ name: address })
    const { connect, connectors, error, isLoading, pendingConnector } = useConnect()



    const [balanceMOC, setBalanceMOC] = useState(null)
    const [balanceBSC, setBalanceBSC] = useState(null)
    const [balanceETH, setBalanceETH] = useState(null)
    const [balancePoS, setBalancePoS] = useState(null)
    const [balanceOP, setBalanceOP] = useState(null)
    const handleBalanceFetchMOC = (value) => {
        setBalanceMOC(value)
    }
    const handleBalanceFetchBSC = (value) => {
        setBalanceBSC(value)
    }
    const handleBalanceFetchETH = (value) => {
        setBalanceETH(value)
    }
    const handleBalanceFetchPoS = (value) => {
        setBalancePoS(value)
    }
    const handleBalanceFetchOP = (value) => {
        setBalanceOP(value)
    }


    const { activeChain } = useAccount();
    const { chains, switchChain } = useSwitchChain();



    return (
        <div className='inner-page'>
            <section className="page-title">
                <div className="overlay"></div>
            </section>

            <GetMOCTokenBalanceOnChain onBalanceFetchMOC={handleBalanceFetchMOC} />{/* Receive MOC Token Blalance when aviable via WEB3 */}
            <GetBalanceBSC onBalanceFetchBSC={handleBalanceFetchBSC} />{/* Receive MOC Token Blalance when aviable via WEB3 */}
            <GetBalanceETH onBalanceFetchETH={handleBalanceFetchETH} />{/* Receive MOC Token Blalance when aviable via WEB3 */}
            <GetBalancePoS onBalanceFetchPoS={handleBalanceFetchPoS} />{/* Receive MOC Token Blalance when aviable via WEB3 */}
            <GetBalanceOP onBalanceFetchOP={handleBalanceFetchOP} />{/* Receive MOC Token Blalance when aviable via WEB3 */}

            <section className="tf-section tf-wallet" data-aos-delay="100" data-aos="fade-up" data-aos-duration="100">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="wallet-form">
                                <h4>Your Wallet</h4>
                                <p className="sub">Please Choose a network & select a wallet to connect to Enter MOC</p>
                                <div className="spacing"></div>

                                <div className="choose-network flat-tabs">
                                    <h6>Choose network</h6>
                                    <ul className="menu-tab row g-2">
                                        <li
                                            className={activeChain?.id === bsc.id ? "active" : ""}
                                            onClick={() => switchChain(bsc.id)}
                                        >
                                            <img src={require("../assets/images/wallets/wallet_bsc.png")} alt="" />
                                            <span>BSC</span>
                                        </li>
                                        <li
                                            className={activeChain?.id === mainnet.id ? "active" : ""}
                                            onClick={() => switchChain(mainnet.id)}
                                        >
                                            <img src={require("../assets/images/wallets/wallet_ethereum.png")} alt="" />
                                            <span>ETH</span>
                                        </li>
                                        <li
                                            className={activeChain?.id === polygon.id ? "active" : ""}
                                            onClick={() => switchChain(polygon.id)}
                                        >
                                            <img src={require("../assets/images/wallets/wallet_polygon.png")} alt="" />
                                            <span>PoS</span>
                                        </li>
                                        <li
                                            className={activeChain?.id === optimism.id ? "active" : ""}
                                            onClick={() => switchChain(optimism.id)}
                                        >
                                            <img src={require("../assets/images/wallets/wallet_optimism.png")} alt="" />
                                            <span>OP</span>
                                        </li>
                                    </ul>
                                </div>


                                {isConnected &&
                                    <div className="choose-network flat-tabs">
                                        <h6>Chain Balance</h6>
                                        <ul className="menu-tab row g-2">
                                            <li className="active">
                                                <img src={require("../assets/images/wallets/moc_logo_blue_round.png")} alt="" />
                                                <span>MOC</span>
                                                <span style={{ color: 'white', marginLeft: '10px' }}>{balanceMOC}</span>
                                            </li>
                                            <li className="active">
                                                <img src={require("../assets/images/wallets/wallet_bsc.png")} alt="" />
                                                <span>BSC</span>
                                                <span style={{ color: 'white', marginLeft: '10px' }}>{balanceBSC}</span>
                                            </li>
                                            <li className="active">
                                                <img src={require("../assets/images/wallets/wallet_ethereum.png")} alt="" />
                                                <span>ETH</span>
                                                <span style={{ color: 'white', marginLeft: '10px' }}>{balanceETH}</span>
                                            </li>
                                            <li className="active">
                                                <img src={require("../assets/images/wallets/wallet_polygon.png")} alt="" />
                                                <span>PoS</span>
                                                <span style={{ color: 'white', marginLeft: '10px' }}>{balancePoS}</span>
                                            </li>
                                            <li className="active">
                                                <img src={require("../assets/images/wallets/wallet_optimism.png")} alt="" />
                                                <span>OP</span>
                                                <span style={{ color: 'white', marginLeft: '10px' }}>{balanceOP}</span>
                                            </li>
                                        </ul>
                                    </div>}

                                {isConnected &&
                                    <div className="top">
                                        Your wallet Connected:
                                    </div>}
                                {isConnected &&
                                    <div>
                                        {ensAvatar && <img alt="ENS Avatar" src={ensAvatar} />}
                                    </div>}
                                {isConnected &&
                                    <div className="top">
                                        {address && <div>{ensName ? `${ensName} (${address})` : address}</div>}
                                    </div>}


                                {isConnected &&
                                    <CoinsDataProvider>
                                        <TokensInWallet_MOC_API />
                                    </CoinsDataProvider>
                                }

                                <div className="connect-wallet">
                                    <h6>Wallet</h6>
                                    <ul>



                                        {isConnected && <li>
                                            <Link onClick={() => disconnect()}>
                                                <img src={require("../assets/images/wallets/wallet_disconnect.png")} alt="" />
                                                <span>Disconnect</span>
                                                <span className="icon">
                                                    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M1.1875 1.375L6.8125 7L1.1875 12.625" stroke="#798DA3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                </span>
                                            </Link>
                                        </li>}


                                        {isDisconnected && connectors.map((connector) => (
                                            <li>
                                                <Link
                                                    disabled={!connector.ready}
                                                    key={connector.uid}
                                                    onClick={() => connect({ connector })}
                                                >
                                                    <img src={require("../assets/images/wallets/wallet_metamask.png")} alt="" />
                                                    <img src={require("../assets/images/wallets/wallet_walletconnect.png")} alt="" />
                                                    <span>{connector.name}
                                                        {/*!connector.ready && ' (unsupported)'*/}
                                                        {isLoading && connector.id === pendingConnector?.id && ' (connecting)'}</span>
                                                    <span className="icon">
                                                        <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M1.1875 1.375L6.8125 7L1.1875 12.625" stroke="#798DA3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    </span>
                                                </Link>
                                            </li>

                                        ))}



                                    </ul>
                                    <div className="bottom">
                                        {error && <div>{error.message}</div>}
                                    </div>
                                </div>
                                <div className="bottom">
                                    By connecting your wallet, you agree to our <Link to="#">Terms of Service</Link> and our <Link to="#">Privacy Policy</Link>.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>




        </div>
    );
}

export default ConnectWallet;