import React from "react"
import Iframe from 'react-iframe'



function MOCchart(props) {


    return (


<div>

{<Iframe className="col-md-12" style={{flex:1,justifyContent: "center",alignItems: "center"}}
id="dextswap-aggregator-widget"
title="DEXTswap Aggregator"
width="500" height="1000"
src="https://www.dextools.io/widget-chart/en/bnb/pe-light/0x6b6e9d8c1d5523f8aaf33f0b2f0186686fdb261c?theme=light&chartType=2&chartResolution=30&drawingToolbars=false"
>
</Iframe>}

</div>




    );
}

export default MOCchart;