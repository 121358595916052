import React from 'react';
import { Link } from 'react-router-dom';


function CTAnft(props) {
    return (
        <section className="tf-section tf_CTA_2">
            <div className="overlay">
            </div>
            <div className="container relative">
            
                <div className="row">
                    <div className="col-md-9">
                        <div className="tf-title left mb0" data-aos="fade-up" data-aos-duration="800">
                            <h2 className="title">
                                Our MOC'er NFT Collection
                            </h2>
                            <p className="sub">Support the project an get an unique nft</p>
                            <div className="wrap-btn">
                                <Link to="https://opensea.io/MOCit" className="tf-button style3">
                                    OpenSea
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                    <div className="image_cta" data-aos="fade-left" data-aos-duration="1200">
                        <img className="move4" src={require ("../../assets/images/NFT/nft (1).png")} alt="" />
                        <img className="icon icon_1" src={require ("../../assets/images/NFT/nft (1)1.png") } alt="" />
                        <img className="icon icon_2" src={require ("../../assets/images/NFT/nft (11).png")} alt="" />
                    </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CTAnft;