import React, { useState, useEffect } from 'react';
import { useReadContract, useChainId, useSwitchChain } from 'wagmi';
import { erc20Abi } from 'viem'
import { bsc, mainnet, polygon } from 'wagmi/chains'
import { ethers } from 'ethers';
import axios from 'axios';
import EmptySpaceUnderHeader from '../../pages/EmptySpaceUnderHeader';


// 0xC5a05951f995cf19b7407820EFEb618Bf590D27a
// 0x2B90E061a517dB2BbD7E39Ef7F733Fd234B494CA


function TokenDexListing() {

    const [contractAddress, setContractAddress] = useState('');
    const [selectedChainId, setSelectedChainId] = useState(mainnet.id);
    const [tokenDetails, setTokenDetails] = useState({
        tokenChainId: null,
        tokenAddress: null,
        tokenName: null,
        tokenSymbol: null,
        tokenDecimals: null,
        tokenTotalSupply: null,
    });
    const [isCorrectToken, setIsCorrectToken] = useState(false);
    const { switchChain, isLoading: isNetworkSwitchLoading } = useSwitchChain();
    const chainId = useChainId();
    const [apiError, setApiError] = useState(null);


    const { data: tokenName, isError: nameError, isLoading: nameLoading, isSuccess: nameSuccess } =
        useReadContract({
            address: contractAddress,
            abi: erc20Abi,
            chainId: selectedChainId,
            functionName: 'name',
            enabled: !!contractAddress,
        });

    const { data: tokenSymbol, isError: symbolError, isLoading: symbolLoading, isSuccess: symbolSuccess } =
        useReadContract({
            address: contractAddress,
            abi: erc20Abi,
            chainId: selectedChainId,
            functionName: 'symbol',
            enabled: !!contractAddress,
        });

    const { data: tokenDecimals, isError: decimalsError, isLoading: decimalsLoading, isSuccess: decimalSuccess } =
        useReadContract({
            address: contractAddress,
            abi: erc20Abi,
            chainId: selectedChainId,
            functionName: 'decimals',
            enabled: !!contractAddress,
        });

    const { data: tokenTotalSupply, isError: supplyError, isLoading: totalSupplyLoading, isSuccess: supplySuccess } =
        useReadContract({
            address: contractAddress,
            abi: erc20Abi,
            chainId: selectedChainId,
            functionName: 'totalSupply',
            enabled: !!contractAddress,
        });



    useEffect(() => {
        setTokenDetails({
            tokenChainId: nameLoading ? 'Loading...' : selectedChainId,
            tokenAddress: nameLoading ? 'Loading...' : contractAddress,
            tokenName: nameLoading ? 'Loading...' : tokenName,
            tokenSymbol: symbolLoading ? 'Loading...' : tokenSymbol,
            tokenDecimals: decimalsLoading ? 'Loading...' : tokenDecimals,
            tokenTotalSupply: totalSupplyLoading ? 'Loading...' : tokenTotalSupply?.toString(),
        });

        setIsCorrectToken(false);
    }, [
        tokenName, nameLoading, nameError,
        tokenSymbol, symbolLoading, symbolError,
        tokenDecimals, decimalsLoading, decimalsError,
        tokenTotalSupply, totalSupplyLoading, supplyError,
        selectedChainId
    ]);

    // Create an Axios instance with the custom HTTPS agent
    const axiosInstance = axios.create({
        baseURL: "https://api.mosquito.network:3006", // Replace with your API URL LOCCAL
        auth: {
            username: "noob",
            password: "mosquitocoin",
        },
    });

    const handleAddressChange = (event) => {
        setContractAddress(event.target.value);
        setIsCorrectToken(false);
    };

    const handleChainChange = async (event) => {
        const newChainId = parseInt(event.target.value);
        setSelectedChainId(newChainId);
        setIsCorrectToken(false);

        if (switchChain) {
            await switchChain(newChainId);
        }
    };



    const handleCorrectToken = async () => {
        try {
          const formattedTotalSupply = ethers.utils.formatUnits(
            tokenDetails.tokenTotalSupply,
            tokenDetails.tokenDecimals
          );
    
          const response = await axiosInstance.post('/api/tokens', {
            ...tokenDetails,
            total_supply: formattedTotalSupply,
          });
    
          console.log('API response:', response);
          setIsCorrectToken(false);
          // (Optional) Reset form or show a success message
        } catch (error) {
          console.error('Error sending data to API:', error);
          if (error.response) {
            // The request was made and the server responded with a status code
            console.error('Server responded with status code:', error.response.status);
            console.error('Response data:', error.response.data);
          } else if (error.request) {
            // The request was made but no response was received
            console.error('No response received:', error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error:', error.message);
          }
          setApiError(
            error.response?.data?.error ||
              'An error occurred while communicating with the API'
          );
        }
      };


    const allDetailsFetched = Object.values(tokenDetails).every(value => value !== null);



    return (
        <div>

            <section>
                <EmptySpaceUnderHeader />
                <EmptySpaceUnderHeader />
            </section>



            <section className="tf-section tf-wallet" data-aos-delay="100" data-aos="fade-up" data-aos-duration="100">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="listing-form">
                                <h1>Token Listing</h1>
                                <h4>automatical listing process</h4>
                                <div>
                                    <input
                                        className='address-input'
                                        type="text"
                                        value={contractAddress}
                                        onChange={handleAddressChange}
                                        placeholder="Enter token contract address"
                                    />
                                    <select className='address-input' value={selectedChainId} onChange={handleChainChange}>
                                        <option value={mainnet.id}>Ethereum Mainnet</option>
                                        <option value={bsc.id}>Binance Smart Chain</option>
                                        <option value={polygon.id}>Polygon</option>
                                        {/* Add more chain options as needed */}
                                    </select>




                                    {contractAddress && (
                                        <div>
                                            <h1>Token Details</h1>
                                            <p>Chain: {tokenDetails.tokenChainId}</p>
                                            <p>Name: {tokenDetails.tokenAddress}</p>
                                            <p>Name: {tokenDetails.tokenName}</p>
                                            <p>Symbol: {tokenDetails.tokenSymbol}</p>
                                            <p>Decimals: {tokenDetails.tokenDecimals}</p>
                                            <p>Total Supply: {tokenDetails.tokenTotalSupply}</p>

                                            {apiError && <p style={{ color: 'red' }}>{apiError}</p>}

                                            {allDetailsFetched && !isCorrectToken && (
                                                <button onClick={() => setIsCorrectToken(true)}>Correct Token</button>
                                            )}
                                        </div>
                                    )}


                                    {isCorrectToken && (
                                        <div>
                                            <button
                                                disabled={isNetworkSwitchLoading || chainId === selectedChainId}
                                                onClick={() => switchChain?.(selectedChainId)}
                                            >
                                                {chainId === selectedChainId ? 'Correct Chain' : `Switch to ${selectedChainId}`}
                                            </button>
                                            <button onClick={handleCorrectToken}>Send to API</button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



        </div>



    );
}

export default TokenDexListing;