//import React from 'react';
import React, { useState, useCallback } from 'react'
import CTA from '../components/cta/cta_v2';

import Airdrop from '../components/airdrop/index';
import NotVIPmessage from '../components/notVIPmessage';
import SOCIAL from '../components/social';
import Banner from '../components/banner/banner_MOC'; 
import dataBanner from '../assets/data/data-banner.js';
import Team from '../components/team';
import dataTeam from '../assets/data/data-team.js';
import SwapUniswap from '../components/swap/uniswap';
import { quote } from '../Hook/UniswapTools/libs/quote.ts';
import { CurrentConfig } from '../Hook/UniswapTools/config.ts';
import SwapPancakeswap from '../components/swap/pancakeswap/swap.jsx';
import { useBalance } from 'wagmi';
import TokensInWallet2 from '../components/WalletTokens/TokensInWallet2.jsx'
import WhitelistedTokensList from '../components/mocTokenList_whitelist/WhitelistedTokens.jsx';
import BannedTokenList from '../components/mocTokenList_Banned/BannedTokens.jsx';
import D3moc from '../components/3Dviewer/3Dmoc.jsx'
import MOCchart from '../components/DexToolsChart/MOCchart.jsx';



import LinksMOC from '../components/LinksMOC/index.jsx';
//import GetTokenPrice_MOC from '../Hook/GetTokenPrice_MOC.jsx';


import TOKENCARDS from '../components/TOKENCARDS/TOKENCARDS.jsx';
//import GetMOCprice from '../Hook/GetMOCprice.jsx';

import TokenTracker from './TokenTracker.jsx';
import useTokenTransactions from '../Hook/BSCSCAN_API/useTokenTransactions.jsx'
import CoinGecoAPI_TokenList from '../components/CoinGecoAPI_TokenList/CoinGecoAPI_TokenList.jsx'
//import CoinTable from '../components/CoinsData_from_Gecko/CoinTable2.jsx';
import ProjectList_Tokens from './Dex.jsx'

import BackgroundWebsite from './DexToolVisitMOCPair.jsx'; // adjust the path as needed

function NotVIP(props) {

  


    return (
        <div className='header-fixed main home1 counter-scroll'>
           {<Banner data={dataBanner} />}


            <NotVIPmessage/>




            {/*
            
            <BackgroundWebsite />

            
            <GetMOCprice>*
            
            
            /}
            {/*TOKENCARDS/>*/}
            {/*useTokenTransactions()*/}

            {/*<TokenTracker/>*/}
            {/*<MOCchart/>*/}
            {/*<GainersLoosers/>*/}
            {/*<ProjectList_Tokens/>*/}
            {/*<CoinTable/>*/}

            {/*<CoinGecoAPI_TokenList/>*/}

            {/*<D3moc/>*/}            {/*A 3D Mosquito Coin*/}
            {/*<WhitelistedTokensList/>*/}
            {/*<BannedTokenList/>*/}
            <CTA />
            {<LinksMOC/>}
            {<SOCIAL/>}

        </div>
    );
}

export default NotVIP;