import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './styles.scss';


Button.propTypes = {
    title: PropTypes.string,
    path: PropTypes.string,
};

function Button(props) {
    const {title , onClick, addclass} = props;
    return (
        <Link title={title} className={`tf-button-web3 ${addclass}`} onClick={onClick}>                                   
            {title} 
        </Link>
    );
}




export default Button;