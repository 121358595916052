import React , { useState , useEffect } from 'react';
import CheckVIPstatus from './Hook/CheckVIPstatus'

import { Route, Routes } from 'react-router-dom';
import routes from './pages';
import routesNotVIP from './pages/indexNotVIP';
import '../src/assets/fonts/font-awesome.css'
import Header from './components/header';
import HeaderNotVIP from './components/headerNotVIP';
import Footer from './components/footer';
import FooterNotVIP from './components/footerNotVIP'


import CoinList from './components/CoinGecoAPI_TokenList/CoinsList';
import TopPoolList from './components/CoinGecoAPI_TokenList/TopPoolList';
import CoinDetails from './components/CoinGecoAPI_TokenList/CoinDetails';
import { StateContext } from './components/CoinGecoAPI_TokenList/StateContext';



function AppVIP() {
 



  if (CheckVIPstatus()){
    return (
        <>
        <Header />

        <Routes>
        
            {
                routes.map((data,idx) => (
                    <Route key={idx} path={data.path} element={data.component} exact />
                ))
            }
        
        </Routes>
        
        <Footer /> 
        </>
          )
  }
else{
    return(
        <>
      <HeaderNotVIP />


      <Routes>
      
        {
            routesNotVIP.map((data,idx) => (
                <Route key={idx} path={data.path} element={data.component} exact />
            ))
        }


      </Routes>

      
      <FooterNotVIP />
        </>
      )
}



}


export default AppVIP;
