const menus = [
    {
        id: 1,
        name: 'MOC',
        links: '/',
    },
    {
        id: 2,
        name: 'Dex',
        links: '/dex',
    },
    {
        id: 3,
        name: 'Listing',
        links: '/DexListing',
    },
    {
        id: 4,
        name: 'Page',
        links: '#',
        namesub: [
            {
                id: 1,
                sub: 'Token',
                links: '/token'
            },
            {
                id: 2,
                sub: 'Roadmap',
                links: '/roadmap',
            },
            {
                id: 3,
                sub: 'Social',
                links: '/social', 
            },
            {
                id: 4,
                sub: 'FAQs',
                links: '/faqs'
            },
            {
                id: 5,
                sub: 'ICO',
                links: '/ico'
            },
            {
                id: 6,
                sub: 'AIRDROP',
                links: '/airdrop'
            },

        ]
    },
    {
        id: 5,
        name: 'Wallet',
        links: '/connect'
    },
]

export default menus;