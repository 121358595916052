import React from 'react';

import CTA from '../components/cta/cta_v2';

import { Link } from 'react-router-dom';

import { 
    useAccount,
    useBalance, 
    isConnected,
    isDisconnected,
    address
} from 'wagmi';

import EmptySpaceUnderHeader from './EmptySpaceUnderHeader'


import { useContractWrite } from 'wagmi'
 
import MOCico from '../components/ABI/mocICO.json'

import { parseEther, parseGwei } from 'viem';
import SOCIAL from '../components/social/index';
import Airdrop from '../components/airdrop';
import AddMOCtoMetamask from '../components/addMOCtoMetamask';

function HomeAirdrop(props) {

    const { address, isConnected, isDisconnected } = useAccount()
    const AddressAirdropClaimer = address;
    const AddressAirdropContract = '0xf7E1124B3799260c5Eed307bC3E0cd110dAf46Fd';

    //const bnbValueAirdrop = parseEther((0.000000047619 * 110000).toString());
    const bnbValueAirdrop = parseEther((0.000000047619 * 55000).toString());

    const { data, error: contractWriteError, isLoading, isSuccess, write } = useContractWrite({
        address: AddressAirdropContract,
        abi: MOCico.abi,
        functionName: 'buyToken',
      })





    return (
        <div className='inner-page'>

            <EmptySpaceUnderHeader/>

            {<SOCIAL/>}
            <section className="tf-section tf-token pt60">
            <div className="container">
            <div className="row">
            <div className="col-md-12">
            <div className="tf-title center mt17 mb40" data-aos="fade-in" data-aos-duration="800">
                            <div className="icon icon3">
                                <img  src={'https://mosquito.network/images/banners/airdropbanner.png'} alt="" /> 
                                </div>
                        </div>
                        </div>
            </div>
            </div>
            </section>
            {<Airdrop/>}

            <section className="tf-section project-info">
                <div className="container">
                    <div className="row">

                    </div>
                </div>
            </section>

            {<AddMOCtoMetamask/>}            
            {<CTA />}
            
        </div>
    );
}

export default HomeAirdrop;