
import HomeNotVIP from "./HomeNotVIP";
import HomeAirdrop from "./HomeAirdrop";
import ConnectWallet from "./ConnectWallet";
import Social from "./Social";
import HomeICO from "./HomeICO";
import Swap from "./Swap";
import About from "./About";
import Dex from "./Dex";
import DexListing from "../components/DexTools_List/TokenDexListing";
import CoinDetailsPage from "../components/DexTools_List/coin-details";






const routes = [
  
  { path: '/', component: <HomeNotVIP />},
  { path: '/airdrop', component: <HomeAirdrop />},
  { path: '/connect', component: <ConnectWallet />},
  { path: '/notVIP', component: <HomeNotVIP />},
  { path: '/ico', component: <HomeICO />},
  { path: '/swap', component: <Swap />},
]


export default routes;