
export const MetamaskAddMOC = () => {
  const tokenAddress = '0xC5a05951f995cf19b7407820EFEb618Bf590D27a';
  const tokenSymbol = 'MOC';
  const tokenDecimals = 18;
  const tokenImage = 'https://mosquito.network/mosquitocoinblue250x250.svg';

  try {
    // 'wasAdded' is a boolean. Like any RPC method, an error can be thrown.
    const wasAdded = window.ethereum.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20',
        options: {
          address: tokenAddress, // The address of the token.
          symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 characters.
          decimals: tokenDecimals, // The number of decimals in the token.
          image: tokenImage, // A string URL of the token logo.
        },
      },
    });

    if (wasAdded) {
      console.log('Thanks for your interest!');
    } else {
      console.log('Your loss!');
    }
  } catch (error) {
    console.log(error);
  }


}


/*
           static addToMetamask(i, n, a, l) {
                i ? window.ethereum ? window.ethereum.request({
                    method: "wallet_watchAsset",
                    params: {
                        type: "ERC20",
                        options: {
                            address: i,
                            symbol: n ?? "unknown",
                            decimals: a,
                            image: l ? f.L.normalizeLogoUrl(l) : ""
                        }
                    }
                }) : console.error("Add to metamask: Metamask is not installed") : console.error("Add to metamask: cannot add to Metamask a token without address")
            }
 */




export default MetamaskAddMOC;