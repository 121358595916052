import { React, useEffect } from 'react';
import AOS from 'aos';
import '../src/assets/fonts/font-awesome.css'


import AppVIP from './AppVIP'
import { WagmiProvider } from 'wagmi';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { config } from './config.ts'

import WalletUpdate from './Hook/MOC_API/WalletUpdate';





function App() {

  const queryClient = new QueryClient()

  useEffect(() => {
    AOS.init({
      duration: 2000
    });


  }, []);




  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <AppVIP />
        <WalletUpdate />
      </QueryClientProvider>
    </WagmiProvider>
  )
}

export default App;