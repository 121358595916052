import React from 'react';

import { useAccount, useBalance } from 'wagmi'

import '../components/header/styles.scss';


  function CheckVIPstatus() {


    const ContractMOC = '0xC5a05951f995cf19b7407820EFEb618Bf590D27a';

        // Web3 MOC
        const { address, isConnected, isDisconnected } = useAccount()

    /*
      const { data, isError, isLoading } = useBalance({
      address: address,
      token: '0xC5a05951f995cf19b7407820EFEb618Bf590D27a',
      chainId: 56,
      watch: true,
  })
  */

  const balanceMOC = useBalance({
    address: address,
    token: ContractMOC,
    chainId: 56,
    watch: true,
    cacheTime: 5_000,
})

   
    if(isDisconnected){
      return false
    }
    /*
    if (isLoading){
      console.log("isLoading");
      return false
    }
    
    if (isError){
      console.log("isError");
      return false
    }
*/

    if (balanceMOC.data?.formatted > 2000000){
      //console.log("Value: "+balanceMOC.data?.formatted)

      return true
    }
    else{
      return false
    }

  



  
  }




export default CheckVIPstatus;