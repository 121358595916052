import React, { useState, useEffect, useContext } from 'react';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css'; // Import theme for styling

import { CoinsDataContext } from "../../ContextProviders/CoinsDataProvider";



function ItemBox({ item }) {
    return (
        <div className="item-box">
            <div className="coin-data-item">
                <td>
                    <img className="mask" src={item?.logoURI} width={50} height={50} alt="" />
                </td>
                <td>
                    <span>{item.name}</span>
                </td>
            </div>
            <div className="td td1">
                <p >{item.symbol}</p>
            </div>
            <div className="td td4">
                <p
                    style={{
                        color: item?.price_change_percentage_h24 > 0
                            ? "green"
                            : item?.price_change_percentage_h24 < 0
                                ? "red"
                                : "grey",
                    }}
                >{item?.price_change_percentage_h24}%
                </p>
            </div>
            {/* Add other properties you want to display */}
            {
                /*
            <p>Listed  date: {new Date(item.lastupdated).toLocaleDateString()}</p>
            */
            }

        </div>
    );
}



function TokenRollerBar() {
    const { dataCoins, setDataCoins } = useContext(CoinsDataContext);
    const [sortedData, setSortedData] = useState([]);

    useEffect(() => {
        const sortAndLimitData = () => {
            const tempData = [...dataCoins];
            tempData.sort((a, b) => new Date(b.lastupdated) - new Date(a.lastupdated));
            setSortedData(tempData.slice(0, 10));
        };

        sortAndLimitData();
    }, [dataCoins]);

    const settings = {
        dots: true, // Enable pagination dots
        infinite: true, // Loop through items seamlessly
        autoplay: true, // Enable autoplay
        autoplaySpeed: 2000, // Autoplay speed in milliseconds
        slidesToShow: 3, // Number of items visible at a time
        slidesToScroll: 1, // Number of items to scroll per swipe
        arrows: false, // Disable default arrows
        responsive: [
            {
                breakpoint: 768, // Responsive settings for smaller screens
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    return (
        <div className="container">
            {
                /*
                            <h1>Top Tokens</h1>
                */
            }

            <div className="item-roller">
                <Slider {...settings}>
                    {sortedData.map((item) => (
                        <ItemBox key={item.id} item={item} />
                    ))}
                </Slider>
            </div>
        </div>
    );
}

export default TokenRollerBar;