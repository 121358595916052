import React from 'react';
import Button from '../button/index'; 

import { 
    useAccount,
    useBalance,
    isConnected,
    isDisconnected,
    address,
} from 'wagmi';

import MetamaskAddMOC from '../../Hook/MetamaskAddMOC';
import { Link } from 'react-router-dom';
import { color } from 'highcharts';




function AddMOCtoMetamask(props) {


    return (
        <section className='inner-page'>


    <section className="tf-section project ">
            <div className="container">
            <div className="wallet-form" >
            <img src={require ("../../assets/images/common/mocTOmetamask.png")} width={300} onClick={MetamaskAddMOC} alt="" />

                <Button
                title={'Add To Metamask'}
                    onClick={() =>
                        MetamaskAddMOC()
                    } addclass='style2'>
                        </Button>
                        </div>
                </div>
                </section>

    </section>
    );
}

export default AddMOCtoMetamask;