import React from 'react';
import CTAnft from '../components/cta/CTAnft';
import CTA3 from '../components/cta/cta_v2';
import ICO from '../components/ICO';
import EmptySpaceUnderHeader from './EmptySpaceUnderHeader'



function HomeICO(props) {
    return (
        <div className='header-fixed main home3 counter-scroll'>
            
            {<EmptySpaceUnderHeader/>}
            {<ICO/>}
            {<CTAnft />}
            {<CTA3 />}            


         
        </div>
    );
}

export default HomeICO;