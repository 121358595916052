import img1 from '../images/common/icon_1.png';
import img2 from '../images/common/icon_2.png';


const dataFeatured = [
    {
        id: 1,
        img: img1,
        title: 'Potential Project',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam at nunc non ligula suscipit tincidunt at sit amet nunc.',
    },
    {
        id: 2,
        img: img2,
        title: 'High Feasibility',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam at nunc non ligula suscipit tincidunt at sit amet nunc.',
    }, 


]

export default dataFeatured;