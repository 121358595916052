import React, { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
//import './styles.scss';
import { Link } from 'react-router-dom';
import Button from '../button connect_disconnect/index';
//import WalletConnector from '../Hook/WalletConnector';
import EmptySpaceUnderHeader from '../../../src/pages/EmptySpaceUnderHeader'

import {
    useAccount,
    useConnect,
    useDisconnect,
    useEnsAvatar,
    useEnsName,
    useBalance,
    useNetwork,
    useSwitchNetwork,
    useSwitchChain
} from 'wagmi'


//import BalanceMOC from '../../Hook/BalanceMOC';
import BalanceBNB from '../../Hook/BalanceBNB';


import GetMOCTokenBalanceOnChain from '../../Hook/Web3/GetMOCTokenBalanceOnChain';
import GetBalanceBSC from '../../Hook/Web3/GetBalanceBSC.jsx';
import GetBalanceETH from '../../Hook/Web3/GetBalanceETH';
import GetBalancePoS from '../../Hook/Web3/GetBalancePoS.jsx';


function PopupWallet(props) {
    const { address, connector, isConnected, isDisconnected } = useAccount()
    const { data: ensName } = useEnsName({ address })
    const { connect, connectors, error, isLoading, pendingConnector } = useConnect()
    const { disconnect } = useDisconnect()



    const handleConnect = async (connector) => {
        await connect({ connector });
    };


    const [balanceMOC, setBalanceMOC] = useState(null)
    const [balanceBSC, setBalanceBSC] = useState(null)
    const [balanceETH, setBalanceETH] = useState(null)
    const [balancePoS, setBalancePoS] = useState(null)
    const handleBalanceFetchMOC = (value) => {
        setBalanceMOC(value)
    }
    const handleBalanceFetchBSC = (value) => {
        setBalanceBSC(value)
    }
    const handleBalanceFetchETH = (value) => {
        setBalanceETH(value)
    }
    const handleBalanceFetchPoS = (value) => {
        setBalancePoS(value)
    }


    const { chain: activeChain } = useAccount();
    const { switchNetwork } = useSwitchChain();




    return (
        <Popup
            trigger={
                <Button title={isConnected ? address.substring(0, 6) + '...' + address.substring(36, 40) : 'Connect'} addclass='style1' />} modal nested>
            {close => (
                <div className="modalPOPUP">

                    <EmptySpaceUnderHeader />


                    <GetMOCTokenBalanceOnChain onBalanceFetchMOC={handleBalanceFetchMOC} />{/* Receive MOC Token Blalance when aviable via WEB3 */}
                    <GetBalanceBSC onBalanceFetchBSC={handleBalanceFetchBSC} />{/* Receive MOC Token Blalance when aviable via WEB3 */}
                    <GetBalanceETH onBalanceFetchETH={handleBalanceFetchETH} />{/* Receive MOC Token Blalance when aviable via WEB3 */}
                    <GetBalancePoS onBalanceFetchPoS={handleBalanceFetchPoS} />{/* Receive MOC Token Blalance when aviable via WEB3 */}

                    <section className="tf-section tf-wallet" data-aos-delay="0" data-aos="fade-up" data-aos-duration="50">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="wallet-form">
                                        <h4>Connect Wallet</h4>
                                        <p className="sub">Please Choose a network & select a wallet to connect to Enter MOC</p>
                                        <div className="spacing"></div>



                                        <div className="choose-network flat-tabs">

                                            <Button
                                                className="button"
                                                title={'Close'}
                                                addclass={'style1'}
                                                onClick={() => {
                                                    console.log('modal closed ');
                                                    close();
                                                }}
                                            >
                                            </Button>

                                        </div>



                                        <div className="choose-network flat-tabs">
                                            <h6>Choose network</h6>
                                            <ul className="menu-tab">
                                                <li className="active">
                                                    <img src={require("../../assets/images/common/wallet_bsc.png")} height={24} alt="" />
                                                    <span>BSC</span>
                                                </li>
                                            </ul>
                                        </div>

                                        {isConnected &&
                                            <div className="top">
                                                Your wallet Connected:
                                            </div>}
                                        {isConnected &&
                                            <div className="top">
                                                {address}
                                            </div>}
                                        {isConnected &&
                                            <div className="choose-network flat-tabs">
                                                <h6>Chain Balance</h6>
                                                <ul className="menu-tab">
                                                    <li className="active">
                                                        <img src={require("../../assets/images/wallets/moc_logo_blue_round.png")} alt="" />
                                                        <span>MOC</span>
                                                        <span style={{ color: 'white', marginLeft: '10px' }}>{balanceMOC}</span>
                                                    </li>
                                                    <li className="active">
                                                        <img src={require("../../assets/images/wallets/wallet_bsc.png")} alt="" />
                                                        <span>BSC</span>
                                                        <span style={{ color: 'white', marginLeft: '10px' }}>{balanceBSC}</span>
                                                    </li>
                                                    <li className="active">
                                                        <img src={require("../../assets/images/wallets/wallet_ethereum.png")} alt="" />
                                                        <span>ETH</span>
                                                        <span style={{ color: 'white', marginLeft: '10px' }}>{balanceETH}</span>
                                                    </li>
                                                    <li className="active">
                                                        <img src={require("../../assets/images/wallets/wallet_polygon.png")} alt="" />
                                                        <span>PoS</span>
                                                        <span style={{ color: 'white', marginLeft: '10px' }}>{balancePoS}</span>
                                                    </li>
                                                </ul>
                                            </div>}

                                        <div className="connect-wallet">
                                            <h6>Connect wallet</h6>

                                            {isConnected ? (
                                                <div className="choose-network">
                                                    <ul>
                                                        <p>Your wallet Connected: </p>
                                                        <p>{address}</p>
                                                        {/* ... (Tokenomics Removed for Brevity) ... */}
                                                        <li onClick={() => disconnect()}>
                                                            <img src={require('../../assets/images/wallets/wallet_disconnect.png')} height={24} width={24} alt="" />
                                                            <span>Disconnect</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            ) : (
                                                <div className="popup-connect-button">
                                                    <ul>
                                                        {connectors.map((connector) => (
                                                            <li key={connector.id}
                                                                disabled={!connector.ready}
                                                                onClick={() => handleConnect(connector)}
                                                            >
                                                                <p></p>
                                                                {/* ... (Image Handling Removed for Brevity) ... */}
                                                                <img src={require('../../assets/images/wallets/wallet_metamask.png')} height={24} width={24} />
                                                                <span>
                                                                    {connector.name}
                                                                    {!connector.ready && ' (unsupported)'}
                                                                </span>
                                                            </li>

                                                        ))}

                                                    </ul>

                                                    {error && <div className="error">{error.message}</div>}
                                                </div>
                                            )}



                                            <div className="bottom">
                                                {error && <div>{error.message}</div>}
                                            </div>
                                        </div>
                                        <div className="bottom">
                                            By connecting your wallet, you agree to our <Link to="#">Terms of Service</Link> and our <Link to="#">Privacy Policy</Link>.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>




                </div>

            )}
        </Popup>

    );


}

export default PopupWallet;