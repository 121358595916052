import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useParams } from 'react-router-dom';
import { GET_coinById, GET_tokenById } from '../../Hook/MOC_API/Get_CoinsData';





//import logo_bsc from "./assets/svg/bscscan.com.svg"
import logo_bsc_scan from "./assets/svg/bsscan.svg"
//import logo_eth from "./assets/svg/ethereum.svg"

//import logo_pancakeswap from "./assets/svg/pancakeswap.svg"
import logo_bubblemaps from "./assets/svg/bubblemaps.svg";
import logo_metamask from "./assets/svg/metamask.svg";



function CoinDetailsPage(props) {
  const { coinId } = useParams();

  const [coinData, setCoinData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const data = await GET_tokenById(coinId);

        // Assuming data is the array you provided
        if (data && data.length > 0) {
          setCoinData(data[0]); // Extract the first coin object
        } else {
          setError("No coin data found");
        }
      } catch (error) {
        setError("Error fetching coin details: " + error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [coinId]);




  function formatNumber(number, currency = false) {
    if (currency) {
      // Use locale-specific formatting for currency
      return number.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    } else {
      // Use comma-separated formatting for large numbers
      return number.toLocaleString();
    }
  }





  const logo_eth = './images/wallets/wallet_ethereum.png';
  const logo_bsc = './images/wallets/wallet_bsc.png';
  const logo_pancakeswap = './images/dex/pancakeswap.png';
  const logo_uniswap = './images/dex/uniswap.png';



  function chainIMG(chainID) {
    if (chainID === 56)
      return logo_bsc
    if (chainID === 1)
      return logo_eth
  }

  function bubblemaps(chainID, address) {
    if (chainID === 56)
      return (`https://app.bubblemaps.io/bsc/token/${address}`)
    if (chainID === 1)
      return (`https://app.bubblemaps.io/eth/token/${address}`)
  }

  function chainScannerAddress(chainID, address) {
    if (chainID === 56)
      return "https://bscscan.com/token/" + address
    if (chainID === 1)
      return "https://etherscan.io/token/" + address
  }


  function dexIMG(dexImage) {
    if (dexImage === "pancakeswap-v3-bsc" || "pancakeswap_v2")
      return logo_pancakeswap
    if (dexImage === "uniswap-bsc")
      return logo_uniswap
  }













  return (

    <section className="tf-dex pt60">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            {isLoading && <p>Loading coin details...</p>}
            {error && <p>Error fetching data: {error.message}</p>}

            {coinData && (
              <div>

                <section>
                  <div className="content-inner coinslist-box-style3_wrapper">
                    <div className="box">
                      <div className="coin-box-content">

                        General
                        <div className="coin-data-grid">
                          <div className="coin-data-item">
                            <td>
                              <img className="mask" src={coinData?.logoURI} width={50} height={50} alt="" />
                            </td>
                            <td>
                              <p>Name</p>
                              <span>{coinData.name}</span>
                            </td>
                          </div>


                          <div className="coin-data-item">
                            <p>Symbol</p>
                            <span>{coinData.symbol}</span>
                          </div>
                          <div className="coin-data-item">
                            <p>Decimals</p>
                            <span>{coinData?.decimals}</span>
                          </div>
                          <div className="coin-data-item">
                            <p>Total Supply</p>
                            <span>{new Intl.NumberFormat('en-US', {
                              minimumFractionDigits: 0, // Ensure at least 8 decimal places
                              maximumFractionDigits: 0  // Limit to 8 decimal places
                            }).format(coinData?.total_supply)}</span>
                          </div>
                          <div className="coin-data-item">
                            <p>Current Price (USD)</p>
                            <span>{new Intl.NumberFormat('en-US', {
                              style: 'currency',
                              currency: 'USD',
                              minimumFractionDigits: 2, // Ensure at least 8 decimal places
                              maximumFractionDigits: 8  // Limit to 8 decimal places
                            }).format(coinData?.price_usd)}</span>
                          </div>
                          <div className="coin-data-item">
                            <p>Contract Address:</p>
                            <span>{coinData?.address}</span>
                          </div>
                          <div className="coin-data-item">
                            <p>Chain</p>
                            <span>{coinData?.chainId}</span>
                          </div>
                          <div className="coin-data-item">
                            <p>Market Cap</p>
                            <span>${coinData?.market_cap_usd}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>




              </div>
            )}

            




          </div>
        </div>
      </div>
    </section>
  );
}

export default CoinDetailsPage;