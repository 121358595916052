import React from 'react';

import Button from '../button/index'; 
import Popup from '../popup/index';
import { useContractWrite } from 'wagmi'
 
import MOCico from '../ABI/mocICO.json'

import { parseEther } from 'viem';
import { parseGwei } from 'viem';

import { 
    useAccount,
    useBalance, 
    isConnected,
    isDisconnected,
    address,
} from 'wagmi';


import { Link } from 'react-router-dom';
import { color } from 'highcharts';



function Airdrop(props) {

    const { address, isConnected, isDisconnected } = useAccount()
    const AddressAirdropClaimer = address;
    const AddressAirdropContract = '0xf7E1124B3799260c5Eed307bC3E0cd110dAf46Fd';

    const bnbValueAirdrop = parseEther((0.000000047619 * 110000).toString());


    const { data, error: contractWriteError, isLoading, isSuccess, write } = useContractWrite({
        address: AddressAirdropContract,
        abi: MOCico.abi,
        functionName: 'buyToken',
      })



    return (
        <div>


        <section className="inner-page">

        <div className="container">
            <div className="row"> 
                <div className="col-md-12">
                <div className="wallet-form">
                    <div className="tf-title mb40" data-aos="fade-up" data-aos-duration="400">
                        <h2 className="title" style={{color: '#86FF00'}}>
                            Airdrop
                        </h2>
                        <h6 className="title">
                            Follow us on Twitter or Threads and grab your airdrop
                        </h6>
                    </div>
                    
 
                    <div className="connect-wallet">

                {isConnected &&
                <Button
                title={'Claim'}
                    disabled={!write}
                    onClick={() =>
                        write({
                            //args: [69],
                            from: AddressAirdropClaimer,
                            value: bnbValueAirdrop,
                            chainId: 56,
                            overrides: { from: AddressAirdropClaimer, value: bnbValueAirdrop, },
                            gasPrice: parseGwei('3'),
                            gas: 200_000n,
                        })
                    } addclass='style2'>
                        </Button>}
                        {isLoading && <div>Check Wallet</div>}
                        {isSuccess && <div>Transaction: {JSON.stringify(data)}</div>}
                        {/*contractWriteError && (
                            <p>
                            Calling that contract function will fail for this reason:
                            {contractWriteError.reason ?? contractWriteError.message}
                            </p>
                            )
                            */}




                            
                {isDisconnected &&
                    <Popup/>}

                    </div>
            </div> 







            </div>
            </div>
        </div>
    </section>


    </div>
    );
}

export default Airdrop;