import BigNumber from "bignumber.js"

export const BIG_ZERO = new BigNumber(0)
export const BIG_ONE = new BigNumber(1)
export const BIG_TWO = new BigNumber(2)
export const BIG_NINE = new BigNumber(9)
export const BIG_TEN = new BigNumber(10)
export const BIG_ONE_HUNDRED = new BigNumber(100)

export const bigIntToSerializedBigNumber = (bigint: bigint): string => bigIntToBigNumber(bigint).toJSON()

export const bigIntToBigNumber = (bigint: bigint): BigNumber => new BigNumber(bigint.toString())

export const formatBigInt = (value: bigint, displayDecimals = 18, decimals = 18): string => {
    const remainder = value % 10n ** BigInt(decimals - displayDecimals)
  
    const formatted = formatUnits(value - remainder, decimals)
    return formatted
  }

  // Copy from viem package to avoid unneessary dependency
function formatUnits(value: bigint, decimals: number) {
    let display = value.toString()
  
    const negative = display.startsWith('-')
    if (negative) display = display.slice(1)
  
    display = display.padStart(decimals, '0')
  
    let [integer, fraction] = [display.slice(0, display.length - decimals), display.slice(display.length - decimals)]
    fraction = fraction.replace(/(0+)$/, '')
    integer = integer || '0'
  
    return `${negative ? '-' : ''}${integer}${fraction ? `.${fraction}` : ''}`
  }

export default BIG_ZERO