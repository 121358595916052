import { Token } from '@uniswap/sdk-core'
import { FeeAmount } from '@uniswap/v3-sdk'
import { USDT_TOKEN, MOC_TOKEN, WBNB_TOKEN } from './libs/constants.ts'

// Inputs that configure this example to run
export interface ExampleConfig {
  rpc: {
    local: string
    mainnet: string
  }
  tokens: {
    in: Token
    amountIn: number
    out: Token
    poolFee: number
  }
}

// Example Configuration

const jsonRpcEndpoint_http_bnb = `https://bsc-dataseed.binance.org/`;
const jsonRpcEndpoint_http_public = `https://bsc.publicnode.com`;
const jsonRpcEndpoint_http_moc = `http://192.168.1.40:8545`;

export const CurrentConfig: ExampleConfig = {
  rpc: {
    local: 'http://192.168.1.40:8545',
    mainnet: jsonRpcEndpoint_http_bnb,
  },
  tokens: {
    in: MOC_TOKEN,
    amountIn: 1,
    out: WBNB_TOKEN,
    poolFee: FeeAmount.MEDIUM,
  },
}