import React, { useEffect, useState } from "react"

//import moc_tokens_list_advertice from './moc_tokens_list_advertice'
//import moc_token_list_banned from '../../assets/data/moc_token_list_banned.json';
import tokenList_getImageUrl from './tokenList_getImageUrl'
import { Link } from 'react-router-dom';
import { color } from 'highcharts';




function BannedTokens(props) {
    const [tokens, setTokens] = useState([])
    const moc_token_list_url_banned = "./moc_token_list_banned_v2.json" //public folder

        

        const fetchData = () => {
            fetch(moc_token_list_url_banned)
              .then(response => {
                return response.json()
              })
              .then(data => {
                setTokens(data)
              })
          }
        
          useEffect(() => {
            fetchData()
          }, [])


    return (
<div>


            <section className="tf-section tf-token pt60">
                <div className="container">
                    <div className="row">  


                        <div className="col-md-12">
                            <div className="tf-title left mt17 mb40" data-aos="fade-up" data-aos-duration="400">
                                <h2 className="title">
                                    Scam Tokens
                                </h2>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="tokenomics-wrapper" data-aos="fade-up" data-aos-duration="800">

                            {tokens.map((token) => (
                                <li>
            <p></p>
          <div className="box">

              <div className="td td1">
              <img
        src={tokenList_getImageUrl(token)}
        className="tokenImage"
        alt={token.name}
      />
              </div>
              <div className="td td1">
                  <p style={{ color: 'red' }}>Symbol</p>
                  <p><Link to={("https://bscscan.com/token/"+token.address)}>{token.symbol}</Link></p>
              </div>
              <div className="td td2">
                  <p style={{ color: 'red' }}>Name</p>
                  <p><Link to={("https://bscscan.com/token/"+token.address)}>{token.name}</Link></p>
              </div>
              <div className="td td3">
                  <p style={{ color: 'red' }}>Address</p>
                  <p><Link to={("https://bscscan.com/token/"+token.address)}>{token.address}</Link></p>
              </div>

          </div>

                                </li>

                            ))}



                            </div>
                        </div>
                    </div>
                </div>
            </section>

            
</div>
    );
}

export default BannedTokens;