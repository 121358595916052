import React, { useState } from 'react';
import axios from 'axios';


// Create an HTTPS agent that doesn't validate certificates


// Create an Axios instance with the custom HTTPS agent
const axiosInstance = axios.create({
    baseURL: "https://api.mosquito.network:3006", // Replace with your API URL LOCCAL
    auth: {
        username: "noob",
        password: "mosquitocoin",
    },
});




/* GET tokens (all tokens) */
export async function GET_tokens() {
    try {
        const response = await axiosInstance.get('/api/tokens');
        const data = response.data;
        return data;
    } catch (error) {
        console.error("Error fetching data:", error); // Assuming serError is a function to handle errors
    }
};
/* GET COIN - coin by id - CoinsData (all tokens that are listed) */
export async function GET_tokenById(tokenId) {
    try {
        const response = await axiosInstance.get(`/api/tokens/${tokenId}`);
        const data = response.data;

        //console.error("Fetch success?"+response.status.toString())
        return data;
    } catch (error) {
        console.error("(GET_tokenById(tokenId)) - Error fetching data:", error); // Assuming serError is a function to handle errors
    }
};

/* GET CoinsData (all tokens that are lsited) */
export async function GET_CoinsData() {
    try {
        const response = await axiosInstance.get('/api/CoinsData');
        const data = response.data;
        return data;
    } catch (error) {
        console.error("Error fetching data:", error); // Assuming serError is a function to handle errors
    }
};
/* GET COIN - coin by id - CoinsData (all tokens that are listed) */
export async function GET_coinById(coinId) {
    try {
        const response = await axiosInstance.get(`/api/CoinsData/${coinId}`);
        const data = response.data;

        return data;
    } catch (error) {
        console.error("(GET_coinsById(coinId)) - Error fetching data:", error); // Assuming serError is a function to handle errors
    }
};
/* GET LISTED TOKENS */
export async function GET_registeredTokenList() {
    try {
        const response = await axiosInstance.get('/api/registeredTokenList');
        const data = response.data;
        return data;
    } catch (error) {
        console.error("Error fetching data:", error);// Assuming serError is a function to handle errors
    }
};
/* GET BANNED LISTED TOKENS */
export async function GET_moc_banned_tokens() {
    try {
        const response = await axiosInstance.get('/api/moc_banned_tokens');
        const data = response.data;
        return data;
    } catch (error) {
        console.error("Error fetching data:", error); // Assuming serError is a function to handle errors
    }
};

export async function GET_pulseCheck() {
    const clientID = 1337;
    try {
        const response = await axiosInstance.get(`/pulsecheck/${clientID}`); 

        if (response.status === 204) {
            console.log("req 204: "+response.status);
          return true;
        } else if (response.status === 404) {
            console.log("req 404: "+response.status);
          return false;
        } else {
            console.log("req fail: "+response.status);
          return false;
        }
      } catch (error) {
        return false;
      }
};





export default GET_CoinsData;