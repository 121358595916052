import React, { useEffect, useState } from "react"

//import moc_tokens_list_advertice from './moc_tokens_list_advertice'
//import moc_tokens_list_whitelist from '../../assets/data/moc_token_list_whitelist.json';
import tokenList_getImageUrl from './tokenList_getImageUrl'
import { Link } from 'react-router-dom';
import { color } from 'highcharts';

import GetTokenPrice from '../../Hook/CoinGecko_API/GetTokenPrice_GECKO'

//uses live data from CoinGecko


function WhitelistedTokens_with_Price(props) {
    const [tokens, setTokens] = useState([])
    const moc_token_list_url_whitelist = "./moc_token_list_whitelist_v2.json" //public folder


    const fetchData = () => {
        fetch(moc_token_list_url_whitelist)
            .then(response => {
                return response.json()
            })
            .then(data => {
                setTokens(data)
            })
    }

    useEffect(() => {
        fetchData()
    }, [])


    const tokenChainId = 'bsc';
    const contract_address_array = tokens.map((token) => (token.address));
    const CoinGecko_get_price_token_array = `https://api.geckoterminal.com/api/v2/simple/networks/${tokenChainId}/token_price/${contract_address_array}`;

    const [Data_token_prices, setData_token_prices] = useState([]);

    useEffect(() => {
        fetch(CoinGecko_get_price_token_array)
            .then(response => response.json())
            .then(data => setData_token_prices(data.data.attributes.token_prices))
            .catch(error => console.error(error));
    }, []);


    return (
        <div>


            <section className="tf-section tf-token pt60">
                <div className="container">
                    <div className="row">


                        <div className="col-md-12">
                            <div className="tf-title left mt17 mb40" data-aos="fade-up" data-aos-duration="800">
                                <h2 className="title">
                                    Top tokens
                                </h2>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="dexlist-wrapper" data-aos="fade-up" data-aos-duration="100">

                                {tokens.map((token) => (
                                    <li>
                                        <p></p>
                                        <div className="box">

                                            <div className="td td1">
                                                <img
                                                    src={tokenList_getImageUrl(token)}
                                                    className="tokenImage"
                                                    alt={token.name}
                                                />
                                            </div>
                                            <div className="td td1">
                                                <p>Symbol</p>
                                                <p><Link to={("https://bscscan.com/token/" + token.address)}>{token.symbol}</Link></p>
                                            </div>
                                            <div className="td td2">
                                                <p>Name</p>
                                                <p><Link to={("https://bscscan.com/token/" + token.address)}>{token.name}</Link></p>
                                            </div>
                                            <div className="td td3">
                                                <p>Address</p>
                                                <p><Link to={("https://bscscan.com/token/" + token.address)}>{token.address}</Link></p>
                                            </div>

                                            <div className="td td10">
                                                <p>Pankcakeswap</p>
                                                <p style={{ color: '#1fc7d4' }}><Link to={("https://pancakeswap.finance/swap?outputCurrency=" + (token.address) + "}&chain=bsc")}>Trade</Link></p>
                                            </div>

                                        </div>

                                    </li>

                                ))}



                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </div>
    );
}

export default WhitelistedTokens_with_Price;