import img1 from '../images/slider/img_slider_1.png';    
import img2 from '../images/common/project_1.png';  
import img3 from '../images/common/project_2.png';  
import img4 from '../images/common/project_3.png';  


const dataProject = [
    {
        id: 1, 
        img: img1
    },  
    {
        id: 2, 
        img: img1
    },  
    {
        id: 3, 
        img: img1
    },  
    {
        id: 4, 
        img: img1
    },  
    {
        id: 5, 
        img: img1
    },  
    {
        id: 6, 
        img: img2,
        title: "Zombie plant 2",
        desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent varius risus sed pellentesque",
        price_1: "100K",
        price_2: "23M",
        price_3: "$0",
    },  
    {
        id: 7, 
        img: img3,
        title: "Zombie plant 2",
        desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent varius risus sed pellentesque",
        price_1: "100K",
        price_2: "23M",
        price_3: "$0",
    },  
    {
        id: 8, 
        img: img4,
        title: "Zombie plant 2",
        desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent varius risus sed pellentesque",
        price_1: "100K",
        price_2: "23M",
        price_3: "$0",
    },  
 
]

export default dataProject;