import React from 'react';
import { useContractRead } from 'wagmi'
import MOCicoABI from '../components/ABI/mocICO_test.json'
import { error } from 'highcharts';
//import { MOCicoABI } from '../components/ABI/MOCico.ts'
import MOCABI from '../components/ABI/MOC.json'

import { 
  useAccount,
  useBalance, 
  isConnected,
  isDisconnected,
  address,
  erc20ABI
} from 'wagmi';
import { etherUnits, weiUnits, formatEther, formatUnits } from 'viem';
import { FixedNumber } from 'ethers';
import { FixedFormat } from '@ethersproject/bignumber';
 


export function ICOread_totalSold() {


  const ContractMOC = "0xC5a05951f995cf19b7407820EFEb618Bf590D27a";
  const ContractMOCico = "0xf7E1124B3799260c5Eed307bC3E0cd110dAf46Fd";

  const { address, isConnected, isDisconnected } = useAccount()

  const MOCicoContract = {
    address: ContractMOCico,
    abi: MOCicoABI,
    functionName: 'hardcap',
  }

  const MOCContract = {
    address: ContractMOC,
    abi: MOCABI.abi,
    args: address,
  }



  const { data: readData, status, isError, isLoading, isFetching: readLoading } = useContractRead({
    address: ContractMOCico,
    abi: MOCicoABI,
    functionName: 'totalSold',
    chainId: 56,
    watch: true,
  });



  if (isLoading) return <div>Fetching...</div>
  if (isError) return <div>Error fetching...</div>


  const remaining = formatUnits(readData, 18)
  const [whole, decimal] = remaining.split('.')

  
  return(
  whole
  )
}





export function ICOread_remaining() {

  const ContractMOCico = "0xf7E1124B3799260c5Eed307bC3E0cd110dAf46Fd";



  const { data: readData, status, isError, isLoading, isFetching: readLoading } = useContractRead({
    address: ContractMOCico,
    abi: MOCicoABI,
    functionName: 'remaining',
    chainId: 56,
    watch: true,
  });



  if (isLoading) return <div>Fetching...</div>
  if (isError) return <div>Error fetching...</div>
  
  const remaining = formatEther(readData)
 const remainingPercent = (remaining / 500 )
 const fixPercent = remainingPercent.toFixed(3) 


  return(
  fixPercent
  )
}


export function ICOread_totalRaised() {

  const ContractMOCico = "0xf7E1124B3799260c5Eed307bC3E0cd110dAf46Fd";



  const { data: readData, status, isError, isLoading, isFetching: readLoading } = useContractRead({
    address: ContractMOCico,
    abi: MOCicoABI,
    functionName: 'totalRaised',
    chainId: 56,
    watch: true,
  });



  if (isLoading) return <div>Fetching...</div>
  if (isError) return <div>Error fetching...</div>


  const remaining = formatUnits(readData, 18)
  const [whole, decimal] = remaining.split('.')
  const remaining1 = whole

  const remaining2 = formatEther(readData)


  
  return(
    remaining2
  )
}






export function ICOread_hardcap() {

  const ContractMOCico = "0xf7E1124B3799260c5Eed307bC3E0cd110dAf46Fd";



  const { data: readData, status, isError, isLoading, isFetching: readLoading } = useContractRead({
    address: ContractMOCico,
    abi: MOCicoABI,
    functionName: 'hardcap',
    chainId: 56,
    watch: true,
  });



  if (isLoading) return <div>Fetching...</div>
  if (isError) return <div>Error fetching...</div>


  const remaining = formatUnits(readData, 18)
  const [whole, decimal] = remaining.split('.')

  
  return(
    whole
  )
}





export default ICOread_remaining;