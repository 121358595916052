import { http, createConfig } from 'wagmi'
import { mainnet, bsc, polygon, arbitrum, optimism} from 'wagmi/chains'
import { injected, metaMask, safe, walletConnect } from 'wagmi/connectors'

const projectId = '<MosquitoNetwork>'

export const config = createConfig({
  chains: [mainnet, bsc, polygon, optimism],
  connectors: [
    injected(),
    //walletConnect({ projectId }),
    //metaMask(),
    //safe(),
  ],
  transports: {
    [bsc.id]: http(),
    [mainnet.id]: http(),
    [polygon.id]: http(),
    [arbitrum.id]: http(),
    [optimism.id]: http(),
  },
})

/*
// Set up wagmi config
const config = createConfig({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ 
      chain: [mainnet, bsc, polygon, arbitrum],
      batch: {
        multicall: {
          batchSize: 1024 * 200,
      },
    },
      options: {
        shimDisconnect: true,
      },
    }),
    new InjectedConnector({
      chain: [mainnet, bsc, polygon, arbitrum],
      batch: {
        multicall: {
          batchSize: 1024 * 200,
      },
    },
      options: {
        name: 'Web3',
        shimDisconnect: true,
      },
    }),
  ],
})
*/
