import React from 'react';

import PageTitle from '../components/pagetitle';
import CTA from '../components/cta/cta_v2';

import icon from '../assets/images/common/down.svg'




function Roadmap(props) {
    return (
        <div className='inner-page'>
            {<PageTitle title='RoadMap' />}

            <section className="tf-section roadmap">
        <div className="container">
            <div className="row"> 
                <div className="col-md-12"> 
                    <div className="roadmap-wrapper-style2" data-aos="fade-up" data-aos-duration="1200">
                        <div className="left">
                            <div className="roadmap-box-style active">
                                <div className="icon">
                                    <img src={icon} alt="" />
                                </div>
                                <div className="content">
                                    <h6 className="date">May 1, 2023</h6>
                                    <ul>
                                        <li>MOC contract created </li>
                                        <li>Created standard token contract for MOC</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="roadmap-box-style active">
                                <div className="icon">
                                    <img src={icon} alt="" />
                                </div>
                                <div className="content">
                                <h6 className="date">May 8, 2023</h6>
                                    <ul>
                                        <li>Airdrop Contract created</li>
                                        <li>Airdrop for those who follow us on twitter or Threads</li>
                                        <li>airdrop.mosquito.network</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="roadmap-box-style">
                                <div className="icon">
                                    <img src={icon} alt="" />
                                </div>
                                <div className="content">
                                    <h6 className="date">July 30, 2024</h6>
                                    <ul>
                                        <li>Listing on CEX</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="right">
                            <div className="roadmap-box-style active">
                                <div className="icon">
                                    <img src={icon} alt="" />
                                </div>
                                <div className="content">
                                <h6 className="date">May 5, 2023</h6>
                                    <ul>
                                        <li>ICO contract created</li>
                                        <li>Created a standard ICO contract</li>
                                        <li>10% of all tokens get sold out</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="roadmap-box-style active">
                                <div className="icon">
                                    <img src={icon} alt="" />
                                </div>
                                <div className="content">
                                <h6 className="date">may 20, 2024</h6>
                                    <ul>
                                        <li>Update of dapp</li>
                                        <li>Total overhault of website</li>
                                        <li>Wagmi, web3, temmplate</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="roadmap-box-style">
                                <div className="icon">
                                    <img src={icon} alt="" />
                                </div>
                                <div className="content">
                                    <h6 className="date">August 16, 2024</h6>
                                    <ul>
                                        <li>Possible to do ICO,IGO, listing</li>
                                        <li>Possible to do Vote, Token, listing</li>
                                    </ul>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div> 
            </div>
        </div>
    </section>
            {<CTA />}
            
        </div>
    );
}

export default Roadmap;