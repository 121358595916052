import HomeVIP from "./HomeVIP"; 
import HomeAirdrop from "./HomeAirdrop";
import HomeThree from "./HomeThree";
import Project1 from "./Project1";
import ProjectList from "./ProjectList";
import ProjectDetails from "./ProjectDetails";
import Token from "./Token";
import ConnectWallet from "./ConnectWallet";
import TeamDetails from "./TeamDetails";




import Roadmap from "./Roadmap";
import BlogGrid from "./BlogGrid";
import BlogList from "./BlogList";
import BlogDetails from "./BlogDetails";
import Contact from "./Contact";
import Social from "./Social";
import PageFaqs from "./PageFaqs";
import HomeICO from "./HomeICO";

import Dex from "./Dex";
import DexListing from "../components/DexTools_List/TokenDexListing";
import CoinDetailsPage from "../components/DexTools_List/coin-details";


const routes = [
  
  { path: '/', component: <HomeVIP />},
  { path: '/Dex', component: <Dex />},
  { path: '/coin-details/:coinId', component: <CoinDetailsPage />},
  { path: '/DexListing', component: <DexListing />},
  { path: '/ico', component: <HomeICO />},
  { path: '/token', component: <Token />},
  { path: '/social', component: <Social />},
  { path: '/airdrop', component: <HomeAirdrop />},
  { path: '/project_details', component: <ProjectDetails />},
  { path: '/home_v2', component: <HomeThree />},
  { path: '/project_v1', component: <Project1 />},
  { path: '/project_list', component: <ProjectList />},
  { path: '/connect', component: <ConnectWallet />},
  { path: '/team_details', component: <TeamDetails />},
  { path: '/faqs', component: <PageFaqs />},
  { path: '/roadmap', component: <Roadmap />},
  { path: '/blog_grid', component: <BlogGrid />},
  { path: '/blog_list', component: <BlogList />},
  { path: '/blog_details', component: <BlogDetails />},
  { path: '/contact', component: <Contact />},

  /*




*/

  




]

export default routes;