import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAccount, useNetwork } from 'wagmi';
import { useChainId } from 'wagmi'

function WalletUpdate() {
  const { address, isConnecting, isConnected, isDisconnected } = useAccount(); 
  const [walletChainId, setWalletChainId] = useState('');
  const [walletName, setWalletName] = useState('');
  const [message, setMessage] = useState('');

  const { chain, chains } = useAccount();
  const currentChain = useChainId();

  // Create an Axios instance with the custom HTTPS agent
const axiosInstance = axios.create({
    baseURL: "https://api.mosquito.network:3006", // Replace with your API URL LOCCAL
    auth: {
        username: "noob",
        password: "mosquitocoin",
    },
});


  useEffect(() => {
    if (isConnected) {
      const updateWallet = async () => {
        try {
          const response = await axiosInstance.post('/api/walletupdate', {
            walletChainId: currentChain,
            walletAddress: address,
            walletName,
            status: isConnected, // Directly set status based on isConnected
          });

          setMessage(response.data.message); 
          console.log("Success update wallet: ")
          console.log(response);

        } catch (error) {
          console.error('Error updating wallet:', error);
          setMessage('Error updating wallet. See console for details.');
        }
      };

      updateWallet(); 
    }
  }, [isConnected, address, walletChainId, walletName]); 


}

export default WalletUpdate;
