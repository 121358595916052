import React from 'react';

import CTA from '../components/cta/cta_v2';

import { Link } from 'react-router-dom';

import EmptySpaceUnderHeader from './EmptySpaceUnderHeader'
//import SwapUniswap from '../components/swap/uniswap';

import MOCchart from '../components/DexToolsChart/MOCchart.jsx';

function Swap(props) {
    return (
        <div className='inner-page'>

            <EmptySpaceUnderHeader/>


            {<MOCchart/>}


            <section className="tf-section project-info">
                <div className="container"> 
                    <div className="row">
                        <div className="col-md-12">
                        {/*<SwapUniswap/>*/}
                        </div>
                    </div>
                </div>
            </section>
            {<CTA />}
            
        </div>
    );
}

export default Swap;