import img1 from '../images/slider/Furore.png';
import img2 from '../images/slider/nft (1).png';
import img3 from '../images/slider/nft (12).png';
import img4 from '../images/slider/nft (19).png';
import imgBanner1 from '../images/slider/banner1.png';
import imgBanner2 from '../images/slider/banner2.png';

const banner_main = "https://mosquito.network/images/banners/main_banner.png"
const banner1 = "https://mosquito.network/images/banners/banner1.png"
const banner2 = "https://mosquito.network/images/banners/banner2.png"
const banner3 = "https://mosquito.network/images/banners/banner3.png"
const banner4 = "https://mosquito.network/images/banners/banner4.png"
const banner5 = "https://mosquito.network/images/banners/banner5.png"
const mocer = "https://mosquito.network/images/banners/MOCer.png"

const dataBanner = [
    {
        id: 1,
        heading: 'Mosquito Coin',
        sub_heading: 'Grow with us!',
        banner_main: banner_main,
        banner2: mocer,
    }, 
    {
        id: 2,
        heading: 'Join first non-MEME coin',
        sub_heading: 'Real voting for the best crypto projects',
        banner_main: banner_main,
        img2: img2,
        img3: img3,
        img4: img4,
    },

]

export default dataBanner;